import {
  Stack,
  Typography,
  Button,
  styled,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { ExternalLink } from "assets/icons/ExternalLink";
import axios from "axios";
import { useState } from "react";
import { ContactSupport } from "shared/components/ContactSupport";
import { colors } from "shared/styles/colors";
import {
  FOREST_ADMIN_URL,
  AUTH0_DASHBOARD_URL,
  NODE_ENV,
} from "../../../shared/config";

const AddSchoolOrUser = () => {
  const [loadingPeriodSync, setLoadingPeriodSync] = useState(false);
  const [toastStatus, setToastStatus] = useState<"error" | "success" | "none">(
    "none"
  );

  const handleSyncPeriods = async () => {
    try {
      setLoadingPeriodSync(true);
      await axios.post("/api/v1/periods/sync");
      setToastStatus("success");
    } catch (error) {
      setToastStatus("error");
      console.error(error);
    } finally {
      setLoadingPeriodSync(false);
    }
  };

  return (
    <Container spacing={3}>
      <Typography variant="h3" fontSize="25px" color={colors.offBlack}>
        Add Schools and Users
      </Typography>
      <Typography
        maxWidth="350px"
        color={colors.secondaryLightGray}
        fontSize="20px"
        lineHeight="normal"
      >
        Add new schools and create new user accounts.
      </Typography>

      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap="12px"
        flexWrap="wrap"
        alignItems={{ xs: "center", lg: "flex-start" }}
      >
        <Link
          href={`${FOREST_ADMIN_URL}/NU%20CIC/Production/Admins/data/Institution/index/record/Institution/create/details`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledButton
            variant="contained"
            endIcon={<ExternalLink color={colors.light} />}
          >
            <TextSpacer>Add School</TextSpacer>{" "}
          </StyledButton>
        </Link>

        <Link
          href={`${AUTH0_DASHBOARD_URL}/dashboard/us/${
            NODE_ENV === "production" ? "nu-cic-prod" : "nu-cic"
          }/users`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledButton
            variant="contained"
            endIcon={<ExternalLink color={colors.light} />}
          >
            <TextSpacer>Add User</TextSpacer>{" "}
          </StyledButton>
        </Link>
        <StyledButton
          variant="contained"
          onClick={handleSyncPeriods}
          disabled={loadingPeriodSync}
        >
          Sync Periods
        </StyledButton>
      </Stack>

      <ContactSupport />
      <Snackbar
        open={toastStatus !== "none"}
        autoHideDuration={6000}
        onClose={() => {
          setToastStatus("none");
        }}
      >
        <Alert
          severity={toastStatus === "error" ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {toastStatus === "error"
            ? "There was an error syncing periods. Please try again."
            : "Institutions were successfully synced."}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddSchoolOrUser;

const Container = styled(Stack)({
  padding: "0 35px 8px",
});

const StyledButton = styled(Button)({
  width: 165,
  height: 50,
  borderRadius: 10,
  flexGrow: 0,
  marginBottom: "12px",
});

const TextSpacer = styled("span")({
  marginRight: 5,
});
