import { Stack, Typography } from "@mui/material";

export const NoResultsFound = () => {
  return (
    <Stack alignItems="center" justifyContent="center" minHeight="250px">
      <Typography fontSize="21px">No results found</Typography>
      <Typography fontSize="15px">
        Please check your spelling or try searching for another school
      </Typography>
    </Stack>
  );
};
