export const StepTwoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      fill="none"
      style={{ maxWidth: "25px", maxHeight: "25px" }}
    >
      <circle cx="12.5" cy="12.5" r="11.5" stroke="#000" strokeWidth="2" />
      <path
        fill="#000"
        d="M14.888 15.58c.163 0 .291.048.385.146.093.093.14.215.14.364V17H8.595v-.51a.916.916 0 0 1 .266-.63l3.136-3.144c.266-.266.501-.52.707-.763.21-.243.387-.485.532-.728.15-.243.261-.488.336-.735.08-.252.119-.516.119-.79 0-.267-.042-.5-.126-.7a1.293 1.293 0 0 0-.336-.505 1.303 1.303 0 0 0-.511-.3 1.924 1.924 0 0 0-.651-.106 2.01 2.01 0 0 0-.637.098 1.628 1.628 0 0 0-.896.693c-.098.159-.17.334-.217.525-.07.191-.161.32-.273.385-.108.065-.266.08-.476.042l-.791-.14c.07-.476.2-.894.392-1.253a3.002 3.002 0 0 1 1.743-1.442 3.94 3.94 0 0 1 1.239-.189c.448 0 .858.068 1.232.203.378.13.702.32.973.567.275.243.49.54.644.89.154.35.23.743.23 1.182 0 .373-.055.719-.167 1.036a4.09 4.09 0 0 1-.441.91c-.187.29-.406.572-.658.847-.248.27-.509.546-.784.826l-2.436 2.492c.2-.056.401-.1.602-.133a3.66 3.66 0 0 1 .58-.049h2.962z"
      />
    </svg>
  );
};
