import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Link,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import format from "date-fns-tz/format";
import { colors } from "shared/styles/colors";
import { Status } from "shared/types/dataCollectionTypes";
import { Course } from "shared/types/generated-types";
import ActiveStatus from "shared/components/ActiveStatus";
import FileDeletionModal from "./FileDeletionModal";
import { useState } from "react";
import axios from "axios";
import { useUserStore } from "stores/useUserStore";
import produce from "immer";
import { useCourseStore } from "stores/useCourseStore";
import BypassCourseModal from "./BypassCourseModal";

interface Props {
  file?: Course | null;
  status: Status;
  type: string;
  periodId: number;
  seasonType: string;
}

const File = ({ file, status, type, periodId, seasonType }: Props) => {
  const [isBypassModalOpen, setIsBypassModalOpen] = useState(false);
  const [isBypassErrorAlertOpen, setIsBypassErrorAlertOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const handleDeletionModalOpen = () => setIsDeletionModalOpen(true);
  const handleDeletionModalClose = () => setIsDeletionModalOpen(false);

  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const [selectedYear, selectedSeason] = useCourseStore((state) => [
    state.selectedYear,
    state.selectedSeason,
  ]);
  const yearId = selectedYear && selectedYear.id;

  const handleDelete = async () => {
    try {
      if (!file || !user) return;
      await axios.delete(`/api/v1/csv/${file.id}?type=${type}`);

      const updatedUser = produce(user, (draftState) => {
        const year = draftState?.institution?.years?.find(
          (currYear) => currYear && currYear.id === yearId
        );
        if (!year) return;
        const period = year.periods.find(
          (currPeriod) => currPeriod && currPeriod.type === selectedSeason
        );
        if (!period) return;

        if (type === "GR") {
          period.graduationAndRetention = period.graduationAndRetention.filter(
            (currGR) => currGR && currGR.id !== file.id
          );
        } else {
          period.Course = period.Course.filter(
            (currCourse) => currCourse && currCourse.id !== file.id
          );
        }
      });

      setUser(updatedUser);
      setIsDeletionModalOpen(false);
    } catch (error) {
      console.error(error);
      // TODO - user feedback?
    }
  };

  const getStatusText = () => {
    switch (status) {
      case Status.PastDue: {
        return "Past Due";
      }

      case Status.Pending: {
        return "Pending";
      }

      case Status.Historical: {
        return "Submission Closed";
      }

      case Status.NotRequired: {
        return "Optional";
      }

      default: {
        return "";
      }
    }
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop={1}
        padding="0 10px 15px 15px"
      >
        <ActiveStatus status={status} />
        <Stack>
          <Typography
            fontSize="12px"
            fontWeight={600}
            marginLeft="6px"
            marginBottom="10px"
          >
            {file?.name ||
              (file && type === "GR" && "Retention & Graduation") ||
              getStatusText()}
          </Typography>

          {file ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              gap={1}
              marginLeft="6px"
            >
              <StyledLink onClick={handleDeletionModalOpen}>
                Delete File
              </StyledLink>
              <Typography color={colors.darkerOffBlack} fontSize="10px">
                Uploaded {format(new Date(file.createdAt), "MM/dd/yyyy")}
              </Typography>
            </Stack>
          ) : (
            <>
              {type !== "GR" && (
                <BypassLink onClick={() => setIsBypassModalOpen(true)}>
                  Bypass Course
                </BypassLink>
              )}
            </>
          )}
        </Stack>
        {file && (
          <FileDeletionModal
            isOpen={isDeletionModalOpen}
            onClose={handleDeletionModalClose}
            onDelete={handleDelete}
            fileName={file.type}
          />
        )}
      </Stack>

      <BypassCourseModal
        isOpen={isBypassModalOpen}
        onClose={() => setIsBypassModalOpen(false)}
        courseType={type}
        seasonType={seasonType}
        periodId={periodId}
        setIsBypassErrorAlertOpen={setIsBypassErrorAlertOpen}
      />

      <Snackbar
        open={isBypassErrorAlertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setIsBypassErrorAlertOpen(false);
        }}
      >
        <Alert severity={"error"} sx={{ width: "100%" }}>
          There was an error bypassing the course. Please try again.
        </Alert>
      </Snackbar>
    </>
  );
};

const StyledLink = styled(Link)({
  textAlign: "center",
  color: colors.link,
  fontSize: "10px",
  fontWeight: 800,
  cursor: "pointer",
});

const BypassLink = styled(Link)({
  fontSize: "12px",
  fontWeight: 600,
  marginLeft: "-18px",
  borderBottom: "1px solid",
  cursor: "pointer",
});

export default File;
