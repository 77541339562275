import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import CallToAction from "./CallToAction";
import WelcomeImg from "../../assets/images/WelcomeImg.png";

const HomeContent = () => {
  return (
    <GridContainer container>
      <Grid item xs={6}>
        <CallToAction />
      </Grid>
      <Grid item xs={6}>
        <Image sx={{ backgroundImage: `url(${WelcomeImg})` }} />
      </Grid>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)({
  width: "100%",
  height: "calc(100vh - 122px - 27px);", // 122 = navbar, 27 = footer
});

const Image = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
});

export default HomeContent;
