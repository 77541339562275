import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colors } from "shared/styles/colors";
import { AdminInstitutionUser } from "shared/types/generated-types";

interface Props {
  users: AdminInstitutionUser[];
  isIntakeFormComplete: boolean;
}

export const Users = ({ users, isIntakeFormComplete }: Props) => {
  return (
    <Container isIntakeFormComplete={isIntakeFormComplete}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeaderText align="left">Name</TableHeaderText>
            <TableHeaderText align="left">Email</TableHeaderText>
            <TableHeaderText align="left">Title</TableHeaderText>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={user.id}>
              <UserName index={index}>{user.name}</UserName>
              <UserEmail index={index}>
                <MailToLink href={`mailto:${user.email}`}>
                  {user.email}
                </MailToLink>
              </UserEmail>
              <UserTitle index={index}>{user.title}</UserTitle>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

const MailToLink = styled("a")({
  color: colors.link,
});

const Container = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== "isIntakeFormComplete",
})(({ isIntakeFormComplete }: { isIntakeFormComplete: boolean }) => ({
  backgroundColor: colors.light,
  paddingBottom: "6px",
  borderLeft: `8px solid ${
    isIntakeFormComplete ? colors.light : colors.purple
  }`,
}));

const UserName = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "index",
})(({ index }: { index: number }) => ({
  fontSize: "12px",
  fontWeight: 600,
  paddingLeft: "16px",
  paddingTop: index === 0 ? "14px" : undefined,
}));

const UserTitle = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "index",
})(({ index }: { index: number }) => ({
  fontSize: "12px",
  color: colors.secondaryLightGray,
  paddingTop: index === 0 ? "14px" : undefined,
}));

const UserEmail = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "index",
})(({ index }: { index: number }) => ({
  fontSize: "12px",
  color: colors.link,
  paddingTop: index === 0 ? "14px" : undefined,
}));

const TableHeaderText = styled(TableCell)({
  fontSize: "12px",
});
