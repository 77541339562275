import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Link, Stack, Typography } from "@mui/material";
import { colors } from "shared/styles/colors";

const Header = () => {
  const { logout } = useAuth0();

  return (
    <HeaderContainer>
      <Stack width="615px">
        <Typography variant="h3" fontSize="40px">
          Welcome
        </Typography>
        <Typography
          variant="body1"
          color={colors.secondaryLightGray}
          fontSize="18px"
        >
          Thank you for your participation! Data uploaded will continue to help
          diversify the field of computing. Check our{" "}
          <ResourcesLink
            href="https://drive.google.com/drive/folders/131ejIOVa0CkHx4zRWOJyS2TABLG1WjNv?usp=sharing"
            target="_blank"
            rel="noopener"
          >
            resources
          </ResourcesLink>{" "}
          to find out more.
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <NoUnderlineLink href="/">
          <MenuText fontWeight={800}>DASHBOARD</MenuText>
        </NoUnderlineLink>
        <NoUnderlineLink onClick={() => logout()} tabIndex={0} href="#">
          <MenuText fontWeight={400}>SIGN OUT</MenuText>
        </NoUnderlineLink>
      </Stack>
    </HeaderContainer>
  );
};

const NoUnderlineLink = styled(Link)({
  textDecoration: "none",
});

const ResourcesLink = styled(Link)({
  fontWeight: "bold",
  textDecoration: "none",
  color: colors.link,
});

const HeaderContainer = styled(Stack)({
  justifyContent: "space-around",
  flexDirection: "row",
  padding: "2rem 0",
  backgroundColor: colors.light,
  borderBottom: `1px solid ${colors.border}`,
});

const MenuText = styled(Typography)({
  fontSize: "14px",
  textTransform: "uppercase",
  margin: "0 1rem",
  cursor: "pointer",
});

export default Header;
