import { useAuth0 } from "@auth0/auth0-react";
import AdminDashboardLayout from "modules/adminDashboard/AdminDashboardLayout";
import DashboardLayout from "modules/dashboard/DashboardLayout";
import HomeHeader from "modules/homePage/HomeHeader";
import { useEffect, useState } from "react";
import HomeContentNoUser from "./HomeContent";

const HomePage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { isAuthenticated, user, isLoading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function checkIfAdmin() {
      const claims = await getIdTokenClaims();

      if (!claims) {
        return;
      }

      if (
        Array.isArray(claims["https://nu-cic/roles"]) &&
        claims["https://nu-cic/roles"].includes("Admin")
      ) {
        setIsAdmin(true);
      }
    }

    checkIfAdmin();
  }, [isLoading, getIdTokenClaims]);

  if (isLoading) {
    return (
      <>
        <HomeHeader isLoading />
        <DashboardLayout isLoading />
      </>
    );
  }

  if (!isAuthenticated || !user) {
    return (
      <>
        <HomeHeader />
        <HomeContentNoUser />
      </>
    );
  }

  if (isAdmin) {
    return (
      <>
        <HomeHeader isLoggedIn isAdmin />
        <AdminDashboardLayout />
      </>
    );
  }

  return (
    <>
      <HomeHeader isLoggedIn />
      <DashboardLayout />
    </>
  );
};

export default HomePage;
