import { colors } from "shared/styles/colors";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const ExternalLink = ({
  width = 19,
  height = 19,
  color = colors.primary,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
    >
      <path
        d="M8.709 3.958h-4.75v11.084h11.083v-4.75M10.292 8.708l5.542-5.542M16.625 2.375h-4.75m4.75 0v4.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
