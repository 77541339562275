import { CheckmarkIcon } from "assets/icons/CheckmarkIcon";
import { colors } from "shared/styles/colors";

interface Props {
  completed: boolean;
  active: boolean;
}

const StepIcon = ({ completed, active }: Props) => {
  const icons: { [index: string]: React.ReactElement } = {
    completed: (
      <CheckmarkIcon
        circleFill={colors.red}
        circleStroke={colors.red}
        checkFill={colors.light}
      />
    ),
    active: <CheckmarkIcon />,
    inactive: <CheckmarkIcon circleStroke="#a4a4a4" checkFill="#a4a4a4" />,
  };
  return <>{icons[completed ? "completed" : active ? "active" : "inactive"]}</>;
};

export default StepIcon;
