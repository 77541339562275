export const StepThreeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      fill="none"
      style={{ maxWidth: "25px", maxHeight: "25px" }}
    >
      <circle cx="12.5" cy="12.5" r="11.5" stroke="#000" strokeWidth="2" />
      <path
        fill="#000"
        d="M9.294 9.884c.07-.476.2-.894.392-1.253a3.002 3.002 0 0 1 1.743-1.442A3.94 3.94 0 0 1 12.668 7c.448 0 .854.065 1.218.196.369.126.684.303.945.532.266.229.469.502.609.819.145.317.217.665.217 1.043 0 .322-.04.607-.119.854a2.001 2.001 0 0 1-.329.644c-.14.187-.313.343-.518.469a3.255 3.255 0 0 1-.693.315c.625.182 1.094.474 1.407.875.313.397.469.896.469 1.498 0 .485-.091.917-.273 1.295-.182.378-.43.698-.742.959-.308.261-.67.462-1.085.602-.41.135-.847.203-1.309.203-.518 0-.966-.06-1.344-.182a3 3 0 0 1-.98-.532 3.07 3.07 0 0 1-.679-.84A5.762 5.762 0 0 1 9 14.651l.651-.273a.799.799 0 0 1 .49-.063.469.469 0 0 1 .343.259c.075.15.159.31.252.483.093.168.212.324.357.469.15.14.331.259.546.357.22.093.488.14.805.14.322 0 .602-.051.84-.154.243-.107.443-.243.602-.406a1.682 1.682 0 0 0 .483-1.169c0-.252-.03-.483-.091-.693a1.088 1.088 0 0 0-.357-.546c-.173-.15-.42-.266-.742-.35-.317-.089-.735-.133-1.253-.133v-1.085a4.296 4.296 0 0 0 1.071-.126 1.96 1.96 0 0 0 .7-.336c.177-.145.303-.317.378-.518.08-.2.119-.42.119-.658a1.8 1.8 0 0 0-.119-.679 1.311 1.311 0 0 0-.336-.49 1.31 1.31 0 0 0-.504-.301 2.05 2.05 0 0 0-.651-.098 2.01 2.01 0 0 0-.637.098 1.628 1.628 0 0 0-.896.693 1.7 1.7 0 0 0-.224.525c-.065.191-.154.32-.266.385-.107.065-.266.08-.476.042l-.791-.14z"
      />
    </svg>
  );
};
