import { Button, Stack, styled, Typography } from "@mui/material";
import axios from "axios";
import produce from "immer";
import { useState } from "react";
import BaseModal from "shared/components/BaseModal";
import { CourseStatus } from "shared/types/generated-types";
import { useCourseStore } from "stores/useCourseStore";
import { useUserStore } from "stores/useUserStore";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  courseType: string;
  seasonType: string;
  periodId: number;
  setIsBypassErrorAlertOpen: (isOpen: boolean) => void;
}

const BypassCourseModal = ({
  isOpen,
  onClose,
  courseType,
  seasonType,
  periodId,
  setIsBypassErrorAlertOpen,
}: Props) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const selectedYear = useCourseStore((state) => state.selectedYear);

  const handleBypassCourse = async () => {
    if (!user || !selectedYear) {
      setIsBypassErrorAlertOpen(true);
      return;
    }

    try {
      setIsLoadingRequest(true);
      const { data: bypassedCourse } = await axios.post("/api/v1/csv/bypass", {
        periodId,
        courseType: courseType,
      });
      const updatedUser = produce(user, (draftState) => {
        const year = draftState?.institution?.years?.find(
          (currYear) => currYear && currYear.id === selectedYear.id
        );
        if (!year) {
          setIsBypassErrorAlertOpen(true);
          return;
        }
        const period = year.periods.find(
          (currPeriod) => currPeriod && currPeriod.type === seasonType
        );
        if (!period) {
          setIsBypassErrorAlertOpen(true);
          return;
        }

        if (courseType === "GR") {
          period.graduationAndRetention.push(bypassedCourse);
          period.status.GR = CourseStatus.Uploaded;
        } else {
          period.Course.push(bypassedCourse);
          period.status[courseType] = CourseStatus.Uploaded;
        }
      });

      setUser(updatedUser);
      onClose();
    } catch (error) {
      console.error(error);
      setIsBypassErrorAlertOpen(true);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  return (
    <BaseModal open={isOpen} onClose={onClose} width="438px" height="370px">
      <Title variant="h1">Bypass Course</Title>
      <Subheader variant="h3" maxWidth={370}>
        Are you sure you want to mark {courseType} for {seasonType} as
        completed? This action will consider this course as fullfilled for this
        term.
      </Subheader>
      <StyledStack>
        <Button
          fullWidth
          variant="contained"
          onClick={handleBypassCourse}
          disabled={isLoadingRequest}
        >
          Mark as completed
        </Button>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Nevermind
        </Button>
      </StyledStack>
    </BaseModal>
  );
};

const Title = styled(Typography)({
  fontSize: "25px",
  textAlign: "center",
  marginTop: "3em",
});

const Subheader = styled(Typography)({
  fontSize: "15px",
  textAlign: "center",
  margin: "1em auto",
});

const StyledStack = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  width: "200px",
  margin: "auto",
  marginTop: "30px",
});

export default BypassCourseModal;
