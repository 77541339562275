import { useState } from "react";
import { CopiedToClipboardStatus } from "shared/types";

export const useCopyEmails = (allEmails: string[]) => {
  const [copiedToClipboardStatus, setCopiedToClipboardStatus] =
    useState<CopiedToClipboardStatus>("none");

  const handleCopyEmails = async () => {
    const emailString = allEmails.join("; ");
    try {
      await navigator.clipboard.writeText(emailString);
      setCopiedToClipboardStatus("success");
    } catch (error) {
      setCopiedToClipboardStatus("error");

      if (process.env.NODE_ENV === "development") {
        console.error("error copying emails", error);
      }
    }
  };

  return {
    handleCopyEmails,
    copiedToClipboardStatus,
    setCopiedToClipboardStatus,
  };
};
