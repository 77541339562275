import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import { DashboardContentContainer } from "shared/styledComponents";
import { colors } from "shared/styles/colors";
import DataCollection from "./dataCollection/DataCollection";
import DataCollectionSkeleton from "./dataCollection/DataCollectionSkeleton";
import UploadInstructions from "./UploadInstructions";

interface Props {
  isLoading?: boolean;
}

const Content = ({ isLoading = false }: Props) => {
  return (
    <Container>
      <ContentContainer gap={4}>
        <DashboardContentContainer
          hasBoxShadow={false}
          width="100%"
          maxWidth="1000px"
        >
          <DataCollection isLoading={isLoading} />
        </DashboardContentContainer>
        <DashboardContentContainer hasBoxShadow width="100%" maxWidth="415px">
          {isLoading ? (
            <DataCollectionSkeleton isUploadInstructions />
          ) : (
            <UploadInstructions />
          )}
        </DashboardContentContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Box)({
  backgroundColor: colors.dashboardBackground,
  padding: "1rem",
  paddingTop: "2rem",
});

const ContentContainer = styled(Stack)({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "1rem 2rem 0 2rem",
});

export default Content;
