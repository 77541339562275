import { Box, Typography, styled } from "@mui/material";
import ActiveStatus from "shared/components/ActiveStatus";
import { InstitutionYearStatus } from "shared/types/generated-types";
import {
  adminSemesterSeasonsMap,
  capitalizeOnlyFirstLetter,
} from "shared/utils";

interface Props {
  yearStatus: InstitutionYearStatus;
  usesQuarters: boolean;
}

const YearDetails = ({ yearStatus, usesQuarters }: Props) => {
  return (
    <Container>
      <YearText>{yearStatus.year}</YearText>
      <OuterFlexContainer>
        {[...yearStatus.periods]
          .sort((a, b) => {
            const usesQuarters = yearStatus.periods.length === 4;
            // only sort semesters
            if (usesQuarters) return 0;

            return (
              adminSemesterSeasonsMap[a.type] - adminSemesterSeasonsMap[b.type]
            );
          })
          .map((period, index) => (
            <PeriodBox className={index < 2 ? "first-two" : ""}>
              <InnerFlexContainer gap={0.5} mb="16px">
                <PeriodText width="110px">
                  {`${capitalizeOnlyFirstLetter(period.type)} ${
                    usesQuarters ? "Quarter" : "Semester"
                  }`}
                </PeriodText>
                {Object.entries(period.status)
                  .filter(([courseName]) => courseName !== "__typename")
                  .map(([courseName, courseStatus]) => (
                    <InnerFlexContainer maxWidth="40%">
                      <InnerFlexContainer gap={0.75}>
                        <ActiveStatus status={courseStatus} />
                        <Typography fontSize={12}>
                          {courseName === "GR" ? "R&G" : `${courseName}, `}
                        </Typography>
                      </InnerFlexContainer>
                    </InnerFlexContainer>
                  ))}
              </InnerFlexContainer>
            </PeriodBox>
          ))}
      </OuterFlexContainer>
    </Container>
  );
};

export default YearDetails;

const Container = styled(Box)({
  paddingLeft: 20,
});

const YearText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
  marginBottom: 14,
});

const PeriodText = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
});

const InnerFlexContainer = styled(Box)({
  display: "flex",
  flexWrap: "nowrap",
});

const OuterFlexContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const PeriodBox = styled(Box)(({ theme }) => ({
  maxWidth: "50%",
  flex: "0 0 50%",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    flex: "0 0 100%",
  },
}));
