import { ChangeEvent, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  AccordionSummary,
  Typography,
  Accordion,
  styled,
  Stack,
  TextField,
  SelectChangeEvent,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import { SearchIcon } from "assets/icons/SearchIcon";
import {
  Filter,
  FilterOptions,
} from "modules/adminDashboard/components/Filter";
import DataCollectionSkeleton from "modules/dashboard/content/dataCollection/DataCollectionSkeleton";
import StatusLegend from "shared/components/StatusLegend";
import { GET_ALL_INSTITUTIONS } from "shared/queries/queries";
import { colors } from "shared/styles/colors";
import {
  AdminInstitution,
  AdminInstitutionStatus,
  Maybe,
} from "shared/types/generated-types";
import { Institutions } from "modules/adminDashboard/components/Institutions";
import { nonNullable } from "shared/utils";
import { ClearSearch } from "assets/icons/ClearSearch";
import { useCopyEmails } from "shared/hooks/useCopyEmails";
import CopiedToClipboardToast from "shared/components/CopiedToClipboardToast";

const ParticipatingSchools = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(FilterOptions.ShowAll);
  let { data, loading, error } = useQuery<{
    getAllInstitutions: Maybe<AdminInstitution>[];
  }>(GET_ALL_INSTITUTIONS);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const handleFilterChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter(event.target.value as FilterOptions);
  };

  const filterInstitutions = (institutions: AdminInstitution[]) => {
    return institutions.filter((institution) => {
      const nameMatches = institution.name
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());

      if (!nameMatches) {
        return false;
      }

      switch (selectedFilter) {
        case FilterOptions.DataUploaded: {
          return institution.status === AdminInstitutionStatus.DataUploaded;
        }
        case FilterOptions.PendingUploads: {
          return institution.status === AdminInstitutionStatus.PendingUpload;
        }
        case FilterOptions.PastDue: {
          return institution.status === AdminInstitutionStatus.PastDue;
        }
        case FilterOptions.ShowAll:
        default: {
          return true;
        }
      }
    });
  };

  const filteredInstitutions = data
    ? filterInstitutions(
        // The nonNullable filter is mostly to satisfy the TS compiler
        data.getAllInstitutions.filter(nonNullable)
      )
    : [];

  const allUserEmails = filteredInstitutions.reduce<string[]>(
    (acc, institution) => {
      const userEmails = institution.users
        ? institution.users
            .map((user) => user?.email)
            .filter((email) => email !== undefined)
        : [];
      return [...acc, ...userEmails] as string[];
    },
    []
  );
  const {
    copiedToClipboardStatus,
    setCopiedToClipboardStatus,
    handleCopyEmails,
  } = useCopyEmails(allUserEmails);

  if (loading) return <DataCollectionSkeleton />;

  if (error || !data?.getAllInstitutions) {
    return (
      <Accordion>
        <AccordionSummary>No institutions found.</AccordionSummary>
      </Accordion>
    );
  }

  return (
    <>
      <Box>
        <Container>
          <Typography variant="h4" fontSize="25px" fontWeight="500">
            Participating Schools
          </Typography>
          <StatusLegend isAdminPage />
        </Container>

        <Container>
          <SearchInput
            onChange={handleSearchChange}
            value={searchText}
            placeholder="Search for school"
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: searchText !== "" && (
                <ClearSearch clearSearch={clearSearch} />
              ),
            }}
          />

          <FilterAndEmailContainer>
            <Filter
              handleFilterChange={handleFilterChange}
              selectedFilter={selectedFilter}
            />
            <EmailAllButton variant="contained" onClick={handleCopyEmails}>
              Copy All Emails
            </EmailAllButton>
          </FilterAndEmailContainer>
        </Container>

        <Institutions institutions={filteredInstitutions} />
      </Box>

      <CopiedToClipboardToast
        copiedToClipboardStatus={copiedToClipboardStatus}
        setCopiedToClipboardStatus={setCopiedToClipboardStatus}
      />
    </>
  );
};

export default ParticipatingSchools;

const Container = styled(Stack)({
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
  justifyContent: "space-between",
  marginBottom: "13px",
  padding: "0 30px 0 27px",
});

const FilterAndEmailContainer = styled(Stack)({
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
});

const EmailAllButton = styled(Button)({
  borderRadius: "50px",
});

const SearchInput = styled(TextField)({
  ".MuiInputBase-input, .MuiInputBase-root": {
    padding: "0 12px 0 22px",
    height: "40px",
    width: "318px",
    borderRadius: "50px",
    fontWeight: 500,

    "&:nth-of-type(2)": {
      paddingLeft: "12px",
    },
  },

  ".MuiInputBase-input::placeholder": {
    fontSize: "14px",
    color: colors.placeholder,
  },

  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  ".MuiInputBase-root": {
    border: "1px solid black",
  },
});
