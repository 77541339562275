import Content from "./content/Content";
import Header from "./Header";

const AdminDashboardLayout = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default AdminDashboardLayout;
