import styled from "@emotion/styled";
import { Box, Link, Typography } from "@mui/material";
import BaseModal from "shared/components/BaseModal";
import { colors } from "shared/styles/colors";
import { ModalContentType } from "./types";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  contentType: ModalContentType;
}

const TermsAndPrivacy = ({ isOpen, handleClose, contentType }: Props) => {
  const currYear = new Date().getFullYear();

  return (
    <BaseModal
      open={isOpen}
      onClose={handleClose}
      width="50%"
      height="75%"
      maxWidth="655px"
      maxHeight="676px"
    >
      <Title>{contentType === "privacy" ? "Privacy Policy" : "Terms"}</Title>
      <Container>
        {contentType === "terms" ? (
          <>
            <Typography fontWeight={800} variant="h2" fontSize="15px">
              Disclaimer
            </Typography>
            <Typography variant="body1">
              CIC and Peerfocus take reasonable steps to ensure the accuracy of
              the information displayed on this Web site. However, neither CIC
              nor Peerfocus is responsible for any consequences or damages
              resulting from errors or inaccuracies in the data or the operation
              of this Web site.
            </Typography>
          </>
        ) : (
          <>
            <Typography fontWeight={800} variant="h2" fontSize="15px">
              Statement of Commitment to Privacy and Confidentiality
            </Typography>
            <ol>
              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  Overview of the Project and Purpose of{" "}
                  <Link
                    href="https://cic.northeastern.edu/wp-content/uploads/sites/5/2023/01/CIC-Statement-of-Commitment-to-Privacy-and-Confidentiality_Spring-2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: colors.link, textDecoration: "underline" }}
                  >
                    this Statement
                  </Link>
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      Housed at Northeastern's Khoury College of Computer
                      Sciences, the Center for Inclusive Computing (the
                      “Center”) awards funding to colleges and universities to
                      scale best practices known to increase the representation
                      of women in undergraduate computing. Collecting data for
                      diagnostic and evaluation purposes is a critical aspect of
                      our work and every grantee participates. The Center and
                      Northeastern University recognize their obligation to use
                      and safeguard the data collected in ways that respect
                      confidentiality and privacy interests, as well as the
                      integrity of the data. This Statement describes how we
                      recognize these concerns and uphold our commitments to
                      address them.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      This Privacy Statement is intended to inform you of our
                      policies and practices regarding our collection, use, and
                      disclosure of the information that you submit to us. If
                      you have any questions regarding this Statement, you can
                      contact us at khoury-cic@northeastern.edu.
                    </Typography>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  What Information Do We Collect?
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      Through our Data Program, we track:
                      <ol type="i">
                        <li>
                          <Typography variant="body1">
                            Student enrollment and persistence and faculty/TA
                            support in introductory Computer Science courses
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Term-to-term retention and graduation of computing
                            majors
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Gender and race/ethnicity of students and faculty/TA
                            support
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            {/* // TODO - add URL to "here" */}
                            More detailed information on data collection can be
                            found here.
                          </Typography>
                        </li>
                      </ol>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Partner schools will be required to submit this data for
                      every term they are receiving funding through the Center,
                      as well as four prior academic terms.
                    </Typography>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  How Do We Collect Your Data?
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      Partner schools will self-report their data through the
                      Center's data collection portal. The Center recognizes
                      that the data obtained from the Survey was self-reported
                      by schools and has not been independently verified for
                      accuracy.
                    </Typography>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  How Do We Use the Data you Submit?
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      School Profile and Self-Evaluation - By collecting and
                      tracking this data, partner schools and their technical
                      advisor will be able to:
                      <ol type="i">
                        <li>
                          Identify precisely where they are losing or gaining
                          students Evaluate the impact of interventions
                        </li>
                        <li>
                          Gain a deeper understanding of the experience of
                          students who are typically underrepresented in
                          computing
                        </li>
                      </ol>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      The Center's Annual Report - The Center for Inclusive
                      Computing will use the data collected by schools in
                      aggregated form to learn more about the state of the field
                      of computing and overall impact of interventions. Any
                      publicizing of data will be in aggregated form and not
                      identify individual institutions.
                    </Typography>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  How Do We Share the Data you report to us?
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      Center Staff and Contracted Partners
                    </Typography>
                    <ol type="i">
                      <li>
                        <Typography variant="body1">
                          Survey data and reports are intended to be used by
                          authorized personnel at the Center for Inclusive
                          Computing to help inform program analysis and
                          decision-making.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Typography variant="body1">Technical Advisors</Typography>
                    <ol type="i">
                      <li>
                        <Typography variant="body1">
                          Each partner school is assigned a technical advisor, a
                          senior level computing faculty with firsthand
                          experience implementing changes that have led to
                          significant increases in the representation of women
                          in computing. Technical advisors will be given access
                          to their assigned schools data to provide guidance on
                          the planning and implementation of interventions and
                          participate in data analysis.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Typography variant="body1">Momentum</Typography>
                    <ol type="i">
                      <li>
                        <Typography variant="body1">
                          Housed at UCLA's School of Education & Information
                          Studies, Momentum conducts research on efforts to
                          diversify computing and technology fields. Momentum
                          researchers gather longitudinal data on students'
                          experiences in computing departments as a way of
                          understanding their trajectories in computing though
                          college and beyond into careers and graduate school.
                          They will establish a baseline survey of first- and
                          second-year students enrolled in computing courses,
                          and longitudinal follow up surveys, at a subset of
                          Center partner schools - your school may be a partner
                          in this effort. In addition to their longitudinal
                          qualitative study, the Center will share data provided
                          by the identified schools through the Survey to the
                          Momentum team.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <Typography fontWeight={800} variant="h2" fontSize="15px">
                  How Do We Protect the Student Data You Report?
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body1">
                      The Center takes the privacy of individuals seriously and
                      care is taken to ensure that information reported does not
                      reveal specific individuals or institutions. The Center
                      protects the data in the following ways:
                    </Typography>
                    <ol type="i">
                      <li>
                        <Typography variant="body1">
                          The Center's data collection portal is hosted on a
                          secure server.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Each user has their own password protected user ID
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Individual department data may be accessed from a
                          secure site only by authorized Center staff and
                          contractors, assigned technical advisor, and
                          authorized Momentum researchers (if applicable, see
                          above)
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          We will not share the data for any purpose other than
                          the analysis and reporting for the data collection
                          initiative outlined above.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <Typography variant="body1">Copyright © {currYear} CIC</Typography>
          </>
        )}
      </Container>
    </BaseModal>
  );
};

const Title = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  marginTop: "1em",
});

const Container = styled(Box)({
  height: "70%",
  marginTop: "1em",
  backgroundColor: colors.lightGray,
  overflowY: "auto",
  padding: "1em 2em",

  "& > p": {
    fontSize: "15px",
    marginTop: "1em",
  },
});

export default TermsAndPrivacy;
