interface Props {
  width?: number;
  height?: number;
  circleFill?: string;
  circleStroke?: string;
  checkFill?: string;
}

export const CheckmarkIcon = ({
  width = 23,
  height = 23,
  circleFill = "#ffffff",
  circleStroke = "#000000",
  checkFill = "#000000",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.5"
        cy="11.5"
        r="10"
        fill={circleFill}
        stroke={circleStroke}
      />
      <path
        d="M9.984 15.531a.708.708 0 0 1-.517-.227l-3.442-3.662a.709.709 0 1 1 1.034-.97l2.918 3.11 5.957-6.517a.71.71 0 1 1 1.048.949l-6.474 7.083a.709.709 0 0 1-.517.234h-.007z"
        fill={checkFill}
      />
    </svg>
  );
};
