import React from "react";
import { AdminInstitution } from "shared/types/generated-types";
import { AdminInstitutionAccordion } from "./AdminInstitutionAccordion";
import { NoResultsFound } from "./NoResultsFound";

interface Props {
  institutions: AdminInstitution[];
}

export const Institutions = React.memo(({ institutions }: Props) => {
  return (
    <>
      {institutions.length === 0 ? (
        <NoResultsFound />
      ) : (
        institutions.map((institution) => (
          <AdminInstitutionAccordion
            institution={institution}
            key={institution.id}
          />
        ))
      )}
    </>
  );
});
