import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { colors } from "shared/styles/colors";
import { AdminInstitution } from "shared/types/generated-types";
import YearDetails from "./YearDetails";

interface Props {
  institution: AdminInstitution;
}

const InstitutionCourseDetails = ({ institution }: Props) => {
  const [numberSelectedYears, setNumberSelectedYears] = useState<
    number | "all"
  >(5);

  const filteredYears = [...institution.institutionYearsStatuses]
    .sort((a, b) => {
      return Number(b.year.split("-")[0]) - Number(a.year.split("-")[0]);
    })
    .filter((_, index) => {
      if (numberSelectedYears === "all") {
        return true;
      }

      return index < numberSelectedYears;
    });

  return (
    <Container isIntakeFormComplete={institution.intakeFormCompleted}>
      <FilterStack>
        <ShowingDataText>Showing data for:</ShowingDataText>
        <SelectInput
          value={numberSelectedYears}
          onChange={(event) => {
            setNumberSelectedYears(event.target.value as number | "all");
          }}
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: colors.paleRed,
              },
              "&& .Mui-selected.Mui-focusVisible": {
                backgroundColor: colors.paleRed,
              },
            },
          }}
        >
          <MenuItem value="all">Show All</MenuItem>
          <MenuItem value={1}>Last Year</MenuItem>
          <MenuItem value={2}>Last 2 Years</MenuItem>
          <MenuItem value={5}>Last 5 Years</MenuItem>
          <MenuItem value={10}>Last 10 Years</MenuItem>
        </SelectInput>
      </FilterStack>

      <Stack pt="18px" pb="50px" gap="29px">
        {filteredYears.map((institutionYearStatus) => (
          <YearDetails
            yearStatus={institutionYearStatus}
            usesQuarters={institution.usesQuarters}
          />
        ))}
      </Stack>
    </Container>
  );
};

export default InstitutionCourseDetails;

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isIntakeFormComplete",
})(({ isIntakeFormComplete }: { isIntakeFormComplete: boolean }) => ({
  paddingTop: 8,
  height: "100%",
  borderLeft: `8px solid ${
    isIntakeFormComplete ? colors.light : colors.purple
  }`,
}));

const FilterStack = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  borderBottom: `1px solid ${colors.divider}`,
  paddingBottom: 9,
  paddingLeft: 16,
});

const SelectInput = styled(Select)({
  borderRadius: "15px",
  border: "1px solid black",
  height: "31px",
  width: "142px",

  ".MuiInputBase-input, .MuiInputBase-root": {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "600",
    color: "black",
  },

  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  ".MuiSvgIcon-root": {
    color: "black",
  },
});

const ShowingDataText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 600,
  color: colors.secondaryLightGray,
});
