import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ACCEPT_TERMS, GET_USER } from "shared/queries/queries";
import TermsModal from "./TermsModal";

const TermsOfService = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [acceptTerms] = useMutation(ACCEPT_TERMS);

  const { isAuthenticated, user } = useAuth0();
  const { data } = useQuery(GET_USER, {
    skip: !isAuthenticated || !user,
    variables: { auth0Id: user?.sub },
  });

  useEffect(() => {
    if (isAuthenticated && data && !data?.user.acceptedTerms) {
      setIsOpen(true);
    }
  }, [data, isAuthenticated]);

  const handleAccept = async () => {
    await acceptTerms();
    setIsOpen(false);
  };

  return (
    <TermsModal
      isOpen={isOpen}
      handleClose={() => {}} // don't let the user close this modal until accepted
      handleAccept={handleAccept}
    />
  );
};

export default TermsOfService;
