export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};

export type AdminInstitution = {
  __typename?: "AdminInstitution";
  id: Scalars["Int"];
  institutionYearsStatuses: Array<InstitutionYearStatus>;
  intakeFormCompleted: Scalars["Boolean"];
  isHistorical: Scalars["Boolean"];
  name: Scalars["String"];
  status: AdminInstitutionStatus;
  statusText: Scalars["String"];
  users?: Maybe<Array<Maybe<AdminInstitutionUser>>>;
  usesQuarters: Scalars["Boolean"];
};

export enum AdminInstitutionStatus {
  DataUploaded = "DATA_UPLOADED",
  PastDue = "PAST_DUE",
  PendingUpload = "PENDING_UPLOAD",
  Unknown = "UNKNOWN",
}

export type AdminInstitutionUser = {
  __typename?: "AdminInstitutionUser";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Course = {
  __typename?: "Course";
  code: Scalars["String"];
  createdAt: Scalars["Date"];
  description: Scalars["String"];
  id: Scalars["Int"];
  isRequiredCourse: Scalars["Boolean"];
  name: Scalars["String"];
  number: Scalars["String"];
  periodId: Scalars["Int"];
  type: Scalars["String"];
  updatedAt: Scalars["Date"];
};

export enum CourseStatus {
  Historical = "HISTORICAL",
  Inactive = "INACTIVE",
  NotRequired = "NOT_REQUIRED",
  PastDue = "PAST_DUE",
  Pending = "PENDING",
  Uploaded = "UPLOADED",
}

export type GraduationAndRetention = {
  __typename?: "GraduationAndRetention";
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  periodId: Scalars["Int"];
  updatedAt: Scalars["Date"];
};

export type Institution = {
  __typename?: "Institution";
  createdAt: Scalars["Date"];
  hasExtraDnaRows?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  intakeFormCompleted: Scalars["Boolean"];
  name: Scalars["String"];
  updatedAt: Scalars["Date"];
  users?: Maybe<Array<Maybe<User>>>;
  usesQuarters: Scalars["Boolean"];
  years?: Maybe<Array<Maybe<Year>>>;
};

export type InstitutionYearStatus = {
  __typename?: "InstitutionYearStatus";
  periods: Array<PeriodStatusInfo>;
  year: Scalars["String"];
};

export type IntakeFormCompleteInput = {
  intakeFormComplete: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  createUser: User;
  intakeFormComplete: Institution;
  userAcceptTerms: User;
};

export type MutationCreateUserArgs = {
  input: UserInput;
};

export type MutationIntakeFormCompleteArgs = {
  input: IntakeFormCompleteInput;
};

export type Period = {
  __typename?: "Period";
  Course: Array<Course>;
  graduationAndRetention: Array<GraduationAndRetention>;
  id: Scalars["Int"];
  status: PeriodStatus;
  type: Scalars["String"];
  yearId: Scalars["Int"];
};

export type PeriodStatus = {
  __typename?: "PeriodStatus";
  CS1: CourseStatus;
  CS2: CourseStatus;
  CS3: CourseStatus;
  GR: CourseStatus;
};

export type PeriodStatusInfo = {
  __typename?: "PeriodStatusInfo";
  status: PeriodStatus;
  type: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getAllInstitutions: Array<Maybe<AdminInstitution>>;
  user?: Maybe<User>;
};

export type QueryUserArgs = {
  auth0Id?: InputMaybe<Scalars["String"]>;
};

export enum Seasons {
  Fall = "FALL",
  Spring = "SPRING",
  Summer = "SUMMER",
  Winter = "WINTER",
}

export type User = {
  __typename?: "User";
  acceptedTerms: Scalars["Boolean"];
  auth0Id: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  institution?: Maybe<Institution>;
  isAdmin: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  auth0Id: Scalars["String"];
};

export type Year = {
  __typename?: "Year";
  id: Scalars["Int"];
  institutionId: Scalars["Int"];
  periods: Array<Period>;
  yearEnd: Scalars["Date"];
  yearStart: Scalars["Date"];
};
