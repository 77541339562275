import { colors } from "shared/styles/colors";

interface Props {
  onClick: () => void;
  width?: number;
  height?: number;
  top?: string;
  right?: string;
}

export const CloseIcon = ({
  onClick,
  width = 27,
  height = 27,
  top = "10px",
  right = "10px",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      onClick={onClick}
      style={{
        cursor: "pointer",
        position: "absolute",
        top: top,
        right: right,
        maxWidth: width,
        maxHeight: height,
        color: colors.primary,
        zIndex: 1,
      }}
    >
      <g clipPath="url(#a)">
        <path
          fill="#B73235"
          d="M15.576 13.5 26.308 2.767A1.472 1.472 0 0 0 24.232.691L13.5 11.424 2.767.677A1.472 1.472 0 0 0 .691 2.753L11.424 13.5.677 24.232a1.472 1.472 0 1 0 2.076 2.076L13.5 15.576l10.732 10.732a1.472 1.472 0 0 0 2.076-2.076L15.576 13.5z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h27v27H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
