import styled from "@emotion/styled";
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { DisabledShowBtn } from "assets/icons/DisabledShowBtn";
import { HideIcon } from "assets/icons/HideIcon";
import { ShowIcon } from "assets/icons/ShowIcon";
import { format, isBefore } from "date-fns";
import React from "react";
import { Accordion } from "shared/components/Accordion";
import { colors } from "shared/styles/colors";
import { DataPeriodCourses, Status } from "shared/types/dataCollectionTypes";
import { Seasons } from "shared/types/generated-types";
import {
  capitalizeOnlyFirstLetter,
  convertToEST,
  getPeriodDueDates,
  shouldBePending,
} from "shared/utils";
import ActiveStatus from "shared/components/ActiveStatus";
import Course from "./Course";

interface Props {
  usesQuarters?: boolean;
  courses: DataPeriodCourses;
  id: string;
  periodId?: number;
  type: Seasons;
  isExpanded: boolean;
  handleChange: (
    panel: string,
    type: Seasons
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  yearStart: number;
  status: Status;
  disabled?: boolean;
}

const Period = ({
  usesQuarters,
  courses,
  isExpanded,
  handleChange,
  id,
  periodId,
  type: seasonType,
  yearStart,
  status,
  disabled,
}: Props) => {
  const hasUploads = Object.values(courses).some(
    (course) => course.data && course.data.length > 0
  );

  const getExpandIcon = () => {
    if (isExpanded) {
      return <HideIcon width={30} height={30} />;
    }
    return disabled ? (
      <DisabledShowBtn width={30} height={30} />
    ) : (
      <ShowIcon width={30} height={30} />
    );
  };

  const nowEST = convertToEST(new Date());
  const [pendingStart, pendingEnd] = getPeriodDueDates({
    season: seasonType,
    year: new Date(yearStart).getFullYear() + 1,
  });

  const isBeforeSubmission = isBefore(nowEST, pendingStart);

  const getStatusText = () => {
    switch (status) {
      case Status.BeforePending: {
        if (!periodId) return "";

        return isBeforeSubmission
          ? `Submission opens on ${format(pendingStart, "MM/dd/yyyy")}`
          : "";
      }
      case Status.Pending: {
        return `Upload data by ${format(pendingEnd, "MM/dd/yyyy")}`;
      }
      case Status.Uploaded: {
        return "Data Uploaded";
      }
      case Status.PastDue: {
        return "Past Due";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <Accordion
      disabled={
        disabled ||
        (status === Status.BeforePending && !hasUploads && isBeforeSubmission)
      }
      expanded={isExpanded}
      onChange={handleChange(id, seasonType)}
    >
      <AccordionSummary>
        <Container width="100%">
          <Container gap={2}>
            <ActiveStatus status={status} />
            <Typography
              fontSize="20px"
              fontWeight="600"
            >{`${capitalizeOnlyFirstLetter(seasonType)} ${
              usesQuarters ? "Quarter" : "Semester"
            }`}</Typography>
          </Container>
          <Container gap={3}>
            <Typography
              fontSize="16px"
              color={colors.secondaryLightGray}
              fontStyle="italic"
              marginRight="18px"
            >
              {getStatusText()}
            </Typography>
            {!disabled && getExpandIcon()}
          </Container>
        </Container>
      </AccordionSummary>

      <Details>
        <DetailsContainer>
          {periodId &&
            Object.entries(courses).map(([courseType, course]) => (
              <Course
                type={courseType}
                seasonType={seasonType}
                courses={course?.data}
                key={course.id}
                status={course.status}
                periodId={periodId}
              />
            ))}
        </DetailsContainer>
      </Details>
    </Accordion>
  );
};

const Container = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 0 12px 6px",
});

const DetailsContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  rowGap: "25px",
  columnGap: "0px",
});

const Details = styled(AccordionDetails)({
  backgroundColor: colors.lightGray,
  padding: "25px 0",
});

export default Period;
