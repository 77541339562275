interface Props {
  width?: number;
  height?: number;
}

export const DeleteIcon = ({ width = 20, height = 22 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#lt1skepfka)">
        <path d="M4.664 19.328h11.137l.614-13H4.05l.614 13z" fill="#F8B9BA" />
        <path
          d="M19.17 4.5h-3.25V2.469c0-.897-.729-1.625-1.625-1.625H6.17c-.896 0-1.625.728-1.625 1.625V4.5h-3.25a.812.812 0 0 0-.813.813v.812c0 .112.092.203.204.203h1.533l.627 13.28c.04.865.757 1.548 1.623 1.548h11.527c.868 0 1.582-.68 1.623-1.549l.627-13.279h1.533a.204.204 0 0 0 .203-.203v-.813a.812.812 0 0 0-.812-.812zM6.373 2.672h7.719V4.5H6.373V2.672zm9.428 16.656H4.664l-.614-13h12.365l-.614 13z"
          fill="#B73235"
        />
      </g>
      <defs>
        <clipPath id="lt1skepfka">
          <path fill="#fff" d="M0 0h20v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
