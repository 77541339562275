import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/system";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  paddingTop: "1rem",
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper,
  "& + &": {
    marginTop: 0,
  },
  "&.Mui-disabled": {
    background: theme.palette.background.paper,
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
