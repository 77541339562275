interface Props {
  width?: number;
  height?: number;
}

export const DataUploaded = ({ width = 18, height = 18 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
    >
      <rect width="18" height="18" rx="9" fill="#4DAC74" />
      <path
        d="M7.288 12a.545.545 0 0 1-.41-.186L4.153 8.809a.594.594 0 0 1 .025-.822.55.55 0 0 1 .794.026l2.312 2.551L12 5.217a.565.565 0 0 1 .394-.215.543.543 0 0 1 .42.148.58.58 0 0 1 .185.42.6.6 0 0 1-.168.426l-5.128 5.812a.562.562 0 0 1-.41.192h-.006z"
        fill="#fff"
      />
    </svg>
  );
};
