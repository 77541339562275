import { z } from "zod";

const envSchema = z.object({
  NODE_ENV: z.string(),
  REACT_APP_GRAPHQL_URI: z.string(),
  REACT_APP_AUTH0_DOMAIN: z.string(),
  REACT_APP_AUTH0_AUDIENCE: z.string(),
  REACT_APP_AUTH0_CLIENT_ID: z.string(),
  REACT_APP_FOREST_ADMIN_URL: z.string().url(),
  REACT_APP_AUTH0_DASHBOARD_URL: z.string().url(),
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_CS1_TEMPLATE_DOWNLOAD: z.string().url(),
  REACT_APP_CS2_TEMPLATE_DOWNLOAD: z.string().url(),
  REACT_APP_CS3_TEMPLATE_DOWNLOAD: z.string().url(),
  REACT_APP_GR_TEMPLATE_DOWNLOAD: z.string().url(),
  REACT_APP_SENTRY_DSN: z.string().url(),
});

const env = envSchema.parse(process.env);

export const NODE_ENV = env.NODE_ENV;
export const GRAPHQL_URI = env.REACT_APP_GRAPHQL_URI;
export const AUTH0_DOMAIN = env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = env.REACT_APP_AUTH0_CLIENT_ID;
export const FOREST_ADMIN_URL = env.REACT_APP_FOREST_ADMIN_URL;
export const AUTH0_DASHBOARD_URL = env.REACT_APP_AUTH0_DASHBOARD_URL;
export const API_URL = env.REACT_APP_API_URL;
export const CS1_TEMPLATE_DOWNLOAD = env.REACT_APP_CS1_TEMPLATE_DOWNLOAD;
export const CS2_TEMPLATE_DOWNLOAD = env.REACT_APP_CS2_TEMPLATE_DOWNLOAD;
export const CS3_TEMPLATE_DOWNLOAD = env.REACT_APP_CS3_TEMPLATE_DOWNLOAD;
export const GR_TEMPLATE_DOWNLOAD = env.REACT_APP_GR_TEMPLATE_DOWNLOAD;
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;
