interface Props {
  width?: number;
  height?: number;
}

export const PastDue = ({ width = 18, height = 18 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
    >
      <rect width="18" height="18" rx="9" fill="#FF5B37" />
      <path
        d="M9.457 4.64v3.718c0 .39-.017.774-.052 1.15-.035.373-.08.776-.136 1.21h-.943c-.06-.434-.108-.837-.143-1.21-.03-.376-.046-.76-.046-1.15V4.64h1.32zm-1.586 8.554c0-.126.022-.243.065-.351a.91.91 0 0 1 .838-.559c.126 0 .243.024.352.071a.91.91 0 0 1 .553.839.878.878 0 0 1-.268.643.767.767 0 0 1-.285.189.861.861 0 0 1-.351.072.877.877 0 0 1-.358-.072.843.843 0 0 1-.286-.188.93.93 0 0 1-.195-.286.954.954 0 0 1-.065-.358z"
        fill="#fff"
      />
    </svg>
  );
};
