import { nanoid } from "nanoid";
import { DataPeriodCourses, Status } from "shared/types/dataCollectionTypes";
import { Seasons } from "shared/types/generated-types";

const courses: DataPeriodCourses = {
  CS1: { id: 1, data: [], status: Status.BeforePending },
  CS2: { id: 2, data: [], status: Status.BeforePending },
  CS3: { id: 3, data: [], status: Status.BeforePending },
  GR: { id: 4, data: [], status: Status.BeforePending },
};

export const quarters = [
  {
    id: nanoid(),
    type: Seasons.Fall,
    courses,
    status: Status.BeforePending,
    periodId: undefined,
  },
  {
    id: nanoid(),
    type: Seasons.Winter,
    courses,
    status: Status.BeforePending,
    periodId: undefined,
  },
  {
    id: nanoid(),
    type: Seasons.Spring,
    courses,
    status: Status.BeforePending,
    periodId: undefined,
  },
  {
    id: nanoid(),
    type: Seasons.Summer,
    courses,
    status: Status.BeforePending,
    periodId: undefined,
  },
];
export const semesters = quarters.filter((q) => q.type !== "WINTER");
