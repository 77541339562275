import { gql } from "@apollo/client";

// get user by auth0Id
export const GET_USER = gql`
  query GetUser($auth0Id: String) {
    user(auth0Id: $auth0Id) {
      id
      auth0Id
      acceptedTerms
      institution {
        id
        name
        createdAt
        updatedAt
        intakeFormCompleted
        usesQuarters
        hasExtraDnaRows
        years {
          id
          yearStart
          yearEnd
          institutionId
          periods {
            id
            yearId
            type
            status {
              CS1
              CS2
              CS3
              GR
            }
            Course {
              id
              createdAt
              updatedAt
              periodId
              isRequiredCourse
              type
              description
              name
              code
              number
            }
            graduationAndRetention {
              id
              createdAt
              updatedAt
              periodId
            }
          }
        }
      }
    }
  }
`;

// accept terms of service for user
export const ACCEPT_TERMS = gql`
  mutation AcceptTerms {
    userAcceptTerms {
      id
      auth0Id
      acceptedTerms
    }
  }
`;

export const CHANGE_INTAKE_FORM_COMPLETED = gql`
  mutation IntakeFormCompleteMutation($input: IntakeFormCompleteInput!) {
    intakeFormComplete(input: $input) {
      id
    }
  }
`;

export const GET_ALL_INSTITUTIONS = gql`
  query GetAllInstitutions {
    getAllInstitutions {
      id
      intakeFormCompleted
      name
      status
      statusText
      usesQuarters
      institutionYearsStatuses {
        year
        periods {
          type
          status {
            CS1
            CS2
            CS3
            GR
          }
        }
      }
      users {
        id
        name
        email
        title
      }
    }
  }
`;
