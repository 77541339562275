import { DataPeriod } from "shared/types/dataCollectionTypes";
import { Seasons, Year } from "shared/types/generated-types";
import { CourseStore } from "stores/types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useCourseStore = create<CourseStore>()(
  devtools((set) => ({
    currentCourseData: [],
    setCurrentCourseData: (data: DataPeriod[]) =>
      set({ currentCourseData: data }),
    selectedYear: null,
    setSelectedYear: (year: Year) => set({ selectedYear: year }),
    selectedCourse: null,
    setSelectedCourse: (course: string) => set({ selectedCourse: course }),
    selectedSeason: null,
    setSelectedSeason: (season: Seasons) => set({ selectedSeason: season }),
  }))
);
