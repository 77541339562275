export const StepOneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      fill="none"
      style={{ maxWidth: "25px", maxHeight: "25px" }}
    >
      <circle cx="12.5" cy="12.5" r="11.5" stroke="#000" strokeWidth="2" />
      <path
        fill="#000"
        d="M15.19 15.859v1.14H9.693v-1.14h2.1V9.545c0-.224.007-.453.021-.686l-1.617 1.365a.567.567 0 0 1-.217.112.496.496 0 0 1-.203 0 .658.658 0 0 1-.168-.063.485.485 0 0 1-.112-.105L9.03 9.51l3.038-2.611h1.225v8.96h1.897z"
      />
    </svg>
  );
};
