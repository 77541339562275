import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { CopiedToClipboardStatus } from "shared/types";

interface Props {
  copiedToClipboardStatus: CopiedToClipboardStatus;
  setCopiedToClipboardStatus: React.Dispatch<
    React.SetStateAction<CopiedToClipboardStatus>
  >;
}

const CopiedToClipboardToast = ({
  copiedToClipboardStatus,
  setCopiedToClipboardStatus,
}: Props) => {
  return (
    <Snackbar
      open={
        copiedToClipboardStatus === "success" ||
        copiedToClipboardStatus === "error"
      }
      autoHideDuration={6000}
      onClose={() => {
        setCopiedToClipboardStatus("none");
      }}
    >
      <Alert
        severity={copiedToClipboardStatus === "error" ? "error" : "success"}
        sx={{ width: "100%" }}
      >
        {copiedToClipboardStatus === "error"
          ? "There was an error copying emails. Please try again."
          : "Emails copied to clipboard."}
      </Alert>
    </Snackbar>
  );
};

export default CopiedToClipboardToast;
