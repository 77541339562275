import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";

const CallToAction = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Container>
      <WelcomeText>Welcome to CIC's Data Collection Portal</WelcomeText>
      {isAuthenticated && user ? (
        // TODO: actual logged in content goes here
        <CallToActionButton
          variant="contained"
          onClick={() => logoutWithRedirect()}
        >
          Sign out {user?.name && `(${user.name})`}
        </CallToActionButton>
      ) : (
        <CallToActionButton
          variant="contained"
          onClick={() => loginWithRedirect()}
        >
          Sign In
        </CallToActionButton>
      )}
    </Container>
  );
};

const Container = styled(Stack)`
  margin-left: 5rem;
  width: calc(100% - 5rem);
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
`;

const WelcomeText = styled(Typography)`
  font-size: 50px;
  max-width: 495px;
`;

const CallToActionButton = styled(Button)`
  background-color: #b73235;
  box-shadow: none;
  text-transform: none;
  padding: 10px 70px;

  &:hover {
    background-color: #b73235;
    box-shadow: none;
  }
`;

export default CallToAction;
