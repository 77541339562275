import { Stack, Typography } from "@mui/material";
import { capitalizeOnlyFirstLetter } from "shared/utils";
import { useCourseStore } from "stores/useCourseStore";
import { useUserStore } from "stores/useUserStore";

const Success = () => {
  const usesQuarters = useUserStore(
    (state) =>
      state.user &&
      state.user.institution &&
      state.user.institution.usesQuarters
  );

  const [selectedCourse, selectedSeason] = useCourseStore((state) => [
    state.selectedCourse,
    state.selectedSeason,
  ]);

  return (
    <Stack
      width="500px"
      textAlign="center"
      margin="auto"
      gap="12px"
      paddingTop="30px"
    >
      {selectedCourse && selectedSeason && (
        <>
          <Typography fontSize={30}>Success</Typography>
          <Typography fontSize={20}>
            Your data for the course:{" "}
            <strong>
              {selectedCourse},{" "}
              {`${capitalizeOnlyFirstLetter(selectedSeason)} ${
                usesQuarters ? "Quarter" : "Semester"
              }`}
            </strong>{" "}
            has been successfully submitted and accepted.
          </Typography>
          <Typography fontSize={20} fontWeight={600}>
            Thank you for your contribution!
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default Success;
