import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { baseThemeOptions } from "shared/styles/baseThemeOptions";
import { Auth0Provider } from "@auth0/auth0-react";
import { authConfig } from "shared/constants";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from "shared/config";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
});

const theme = createTheme(baseThemeOptions);
const { domain, clientId, audience } = authConfig;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience={audience}
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//fonts.googleapis.com/css?family=Lato:400,700,900&display=swap"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>
);
