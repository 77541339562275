import { Link, Typography } from "@mui/material";
import { CIC_SUPPORT_EMAIL_LINK } from "shared/constants";
import { colors } from "shared/styles/colors";

interface Props {
  fontSize?: string;
}

export const ContactSupport = ({ fontSize }: Props) => {
  return (
    <Typography color={colors.secondaryLightGray} fontSize={fontSize ?? "16px"}>
      Need help?{" "}
      <Link href={CIC_SUPPORT_EMAIL_LINK} color={colors.link} fontWeight={600}>
        Contact Support
      </Link>
    </Typography>
  );
};
