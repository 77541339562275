import type { ThemeOptions } from "@mui/material";
import { colors } from "./colors";

export const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary,
      light: colors.lightPrimary,
      contrastText: colors.light,
    },
    text: {
      primary: colors.primary,
      secondary: colors.secondaryLightGray,
    },
    divider: colors.divider,
  },
  typography: {
    fontFamily: `"Lato", sans-seriff`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          boxShadow: "none",
          textTransform: "none",
          borderRadius: "15px",
        },
        contained: {
          backgroundColor: colors.red,
          "&:hover": {
            backgroundColor: colors.red,
            boxShadow: "none",
          },
        },
        outlined: {
          border: `2px solid ${colors.primary}`,
          "&:hover": {
            border: `2px solid ${colors.primary}`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
};
