import { Skeleton, Stack } from "@mui/material";

interface Props {
  isUploadInstructions?: boolean;
}

const DataCollectionSkeleton = ({ isUploadInstructions }: Props) => {
  return (
    <Stack spacing={2}>
      {!isUploadInstructions && (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Skeleton variant="text" width="18%" height={50} />
          <Skeleton variant="text" width="32%" height={50} />
        </Stack>
      )}

      <Skeleton variant="text" width="100%" height={60} />
      <Stack spacing={0.5}>
        {[1, 2, 3].map((i) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={0.5}
            key={i}
          >
            {isUploadInstructions && (
              <Skeleton variant="circular" width="25px" />
            )}
            <Skeleton variant="rectangular" width="100%" height={100} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default DataCollectionSkeleton;
