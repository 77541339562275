import { Box, styled } from "@mui/material";
import { colors } from "./styles/colors";

interface IntakeFormCompletedIndicatorProps {
  isIntakeFormComplete: boolean;
}

export const IntakeFormCompletedIndicator = styled(Box, {
  // This prevents React from throwing an unrecognized prop warning
  shouldForwardProp: (prop) => prop !== "isIntakeFormComplete",
})(({ isIntakeFormComplete }: IntakeFormCompletedIndicatorProps) => ({
  position: "absolute",
  left: 0,
  width: "8px",
  height: "100%",
  backgroundColor: isIntakeFormComplete ? "transparent" : colors.purple,
}));

export const DashboardContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasBoxShadow",
})(({ hasBoxShadow }: { hasBoxShadow: boolean }) => ({
  backgroundColor: colors.light,
  border: `1px solid ${colors.border}`,
  borderRadius: "10px",
  padding: "2rem",
  boxShadow: hasBoxShadow ? "0 8px 8px 0 rgba(157, 157, 157, 0.25)" : "",
}));
