import styled from "@emotion/styled";
import LogoWide from "../../assets/images/LogoWide.png";

// ? Is Logo an appropriate name for this? Maybe more of a title?
const Logo = () => {
  return (
    <LogoImage
      src={LogoWide}
      alt="Northeastern University, Center for Inclusive Computing"
    />
  );
};

export default Logo;

const LogoImage = styled.img`
  width: 394px;
  padding: 1rem 2rem;
`;
