import { Button, styled } from "@mui/material";
import { DownloadIcon } from "assets/icons/DownloadIcon";
import {
  CS1_TEMPLATE_DOWNLOAD,
  CS2_TEMPLATE_DOWNLOAD,
  CS3_TEMPLATE_DOWNLOAD,
  GR_TEMPLATE_DOWNLOAD,
} from "shared/config";
import { CourseType } from "shared/constants";

interface Props {
  selectedCourse: string;
}

export const TemplateDownloadButton = ({ selectedCourse }: Props) => {
  const handleDownload = () => {
    const link = document.createElement("a");

    switch (selectedCourse) {
      case CourseType.CS1: {
        link.href = CS1_TEMPLATE_DOWNLOAD;
        break;
      }
      case CourseType.CS2: {
        link.href = CS2_TEMPLATE_DOWNLOAD;
        break;
      }
      case CourseType.CS3: {
        link.href = CS3_TEMPLATE_DOWNLOAD;
        break;
      }
      default: {
        link.href = GR_TEMPLATE_DOWNLOAD;
        break;
      }
    }

    link.click();
  };

  return (
    <DownloadButton variant="contained" onClick={handleDownload}>
      Download Template File
      <DownloadIcon marginLeft={20} />
    </DownloadButton>
  );
};

const DownloadButton = styled(Button)({
  fontSize: "20px",
  borderRadius: "50px",
  padding: "16px 74px",
  marginBottom: "54px",
});
