interface Props {
  clearSearch: () => void;
}

export const ClearSearch = ({ clearSearch }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      style={{ minWidth: "30px", minHeight: "30px", cursor: "pointer" }}
      onClick={clearSearch}
    >
      <path
        fill="#2B8DCC"
        d="m13.372 14.875-3.004-3.005a1.062 1.062 0 0 1 1.502-1.502l3.005 3.005 3.005-3.005a1.062 1.062 0 1 1 1.502 1.502l-3.005 3.005 3.005 3.005a1.061 1.061 0 0 1-.748 1.826 1.062 1.062 0 0 1-.755-.324l-3.004-3.005-3.005 3.005a1.063 1.063 0 0 1-1.502-1.502l3.004-3.005z"
      />
      <path
        fill="#2B8DCC"
        d="M14.875 27.625a12.75 12.75 0 1 0 0-25.501 12.75 12.75 0 0 0 0 25.501zm0 2.125a14.875 14.875 0 1 1 0-29.75 14.875 14.875 0 0 1 0 29.75z"
      />
    </svg>
  );
};
