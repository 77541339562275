import "./App.css";
import { Routes, Route } from "react-router-dom";
import { routes } from "routes";
import Footer from "shared/components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { setAxiosBaseUrl, setDefaultAuthHeader } from "shared/utils";
import { useClient } from "shared/apollo/useClient";
import { ApolloProvider } from "@apollo/client";
import TermsOfService from "modules/termsOfService/TermsOfService";
import styled from "@emotion/styled";
import { colors } from "shared/styles/colors";

function App() {
  const [authToken, setAuthToken] = useState("");

  const client = useClient(authToken);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const routeElements = routes.map((route) => <Route {...route} />);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setAuthToken(token);
          setDefaultAuthHeader(token);
          setAxiosBaseUrl();
        }
      } catch (error) {
        console.error(error);
      }
    };

    isAuthenticated && getAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <ApolloProvider client={client}>
      <Wrapper>
        <TermsOfService />
        <Routes>{routeElements}</Routes>
        <StyledFooter>
          <Footer />
        </StyledFooter>
      </Wrapper>
    </ApolloProvider>
  );
}

export default App;

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: colors.dashboardBackground,
});

const StyledFooter = styled.footer({
  marginTop: "auto",
});
