import axios from "axios";
import { isAfter, isBefore } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { API_URL } from "./config";
import { CourseUploadStatus } from "./constants";
import { Status } from "./types/dataCollectionTypes";
import { CourseStatus, PeriodStatus, Seasons } from "./types/generated-types";

export const setDefaultAuthHeader = (accessToken: string) =>
  (axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`);

export const setAxiosBaseUrl = () => {
  if (process.env.NODE_ENV === "production") {
    axios.defaults.baseURL = API_URL;
  } else {
    axios.defaults.baseURL = "http://localhost:8080";
  }
};

export const capitalizeOnlyFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const getCurrentStartingYear = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  return currentMonth > 8 ? currentYear : currentYear - 1;
};

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const convertToEST = (time: Date) =>
  utcToZonedTime(zonedTimeToUtc(time, timeZone), "America/New_York");

const seasonUploadStartMonths = {
  [Seasons.Fall]: 1,
  [Seasons.Winter]: 4,
  [Seasons.Spring]: 7,
  [Seasons.Summer]: 9,
};
const seasonUploadEndMonths = {
  [Seasons.Fall]: 3,
  [Seasons.Winter]: 6,
  [Seasons.Spring]: 10,
  [Seasons.Summer]: 11,
};

export const getPeriodDueDates = ({
  season,
  year,
}: {
  season: Seasons;
  year: number;
}) => {
  return [
    convertToEST(new Date(year, seasonUploadStartMonths[season] - 1, 1)),
    convertToEST(new Date(year, seasonUploadEndMonths[season] - 1, 15)),
  ];
};

export const shouldBePending = ({
  pendingStart,
  pendingEnd,
  now,
}: {
  pendingStart: Date;
  pendingEnd: Date;
  now: Date;
}) => {
  const nowEST = convertToEST(now);
  const pendingStartEST = convertToEST(pendingStart);
  const pendingEndEST = convertToEST(pendingEnd);

  return isAfter(nowEST, pendingStartEST) && isBefore(nowEST, pendingEndEST);
};

export const getPeriodStatus = (periodStatus: PeriodStatus) => {
  const statuses = Object.values(periodStatus).filter(
    (status) => status !== "PeriodStatus"
  );

  const isHistorical = statuses.includes("HISTORICAL" as CourseStatus);

  const isUploaded = !isHistorical
    ? statuses.every(
        (status) => (status as Status) === Status.Uploaded // TODO - refactor Status to use the same enum as backend (string values are still the same, so it will work with type casting)
      )
    : statuses.some((status) => (status as Status) === Status.Uploaded);
  const isPastDue = statuses.some(
    (status) => (status as Status) === Status.PastDue
  );
  const isPending = statuses.some(
    (status) => (status as Status) === Status.Pending
  );
  const isBeforePending = statuses.some(
    (status) => (status as Status) === Status.BeforePending
  );

  if (isUploaded) {
    return Status.Uploaded;
  } else if (isPastDue) {
    return Status.PastDue;
  } else if (isPending) {
    return Status.Pending;
  } else if (isHistorical) {
    return Status.Historical;
  } else if (isBeforePending) {
    return Status.BeforePending;
  } else {
    return Status.NotRequired;
  }
};

export const returnStatusForCourse = ({
  yearStart,
  coursePeriod,
  usesQuarters,
  fileName,
}: {
  yearStart: Date;
  coursePeriod: Seasons;
  usesQuarters: boolean;
  fileName?: string;
}) => {
  if (fileName) {
    return CourseUploadStatus.Uploaded;
  }

  const now = new Date();

  if (usesQuarters) {
    switch (coursePeriod) {
      case Seasons.Spring: {
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`7/1/${year}`);
        const pendingEnd = new Date(`10/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
      case Seasons.Summer: {
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`9/1/${year}`);
        const pendingEnd = new Date(`11/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
      case Seasons.Fall: {
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`1/1/${year}`);
        const pendingEnd = new Date(`3/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
      default: {
        // Seasons.Winter
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`4/1/${year}`);
        const pendingEnd = new Date(`6/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
    }
  } else {
    switch (coursePeriod) {
      case Seasons.Spring: {
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`7/1/${year}`);
        const pendingEnd = new Date(`10/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
      case Seasons.Summer: {
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`9/1/${year}`);
        const pendingEnd = new Date(`11/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
      default: {
        // Seasons.Fall
        const year = yearStart.getFullYear() + 1;
        const pendingStart = new Date(`1/1/${year}`);
        const pendingEnd = new Date(`3/15/${year}`);

        if (shouldBePending({ pendingStart, pendingEnd, now })) {
          return CourseUploadStatus.Pending;
        } else {
          return CourseUploadStatus.PastDue;
        }
      }
    }
  }
};

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export const validateLength = (unformattedCsv: string[][], type: string) => {
  let lengthError = false;

  if (type === "GR") {
    lengthError = unformattedCsv.length !== 25;
  } else if (type === "CS1") {
    lengthError = unformattedCsv.length !== 46;
  } else if (type === "CS2") {
    lengthError = unformattedCsv.length !== 45;
  } else {
    lengthError = unformattedCsv.length !== 44;
  }

  if (lengthError) {
    return {
      message:
        "Invalid CSV length detected. Please ensure you have selected the correct file and have not added or removed any rows.",
      cellLocation: "N/A",
    };
  }

  return null;
};

export const adminSemesterSeasonsMap = {
  [Seasons.Fall]: 1,
  [Seasons.Summer]: 2,
  [Seasons.Spring]: 3,
};
