import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./config";

export const CIC_SUPPORT_EMAIL_LINK = "mailto:khoury-cic@northeastern.edu";

export const authConfig = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
};

export enum CourseUploadStatus {
  Uploaded = "Uploaded",
  Pending = "Pending",
  PastDue = "Past Due",
}

export enum CourseType {
  CS1 = "CS1",
  CS2 = "CS2",
  CS3 = "CS3",
  GR = "Retention & Graduation",
}
