import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "shared/styles/colors";
import { ValidationError } from "./types";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { DownloadIcon } from "assets/icons/DownloadIcon";

interface Props {
  errors: ValidationError[];
  isLoading: boolean;
  fileName: string;
}

const Validate = ({ errors, isLoading, fileName }: Props) => {
  const [csvDownload, setCsvDownload] = useState<null | Blob>(null);

  useEffect(() => {
    const convertErrorsToCsv = () => {
      const convertedErrors = errors.map((error) => {
        return [error.message, error.cellLocation];
      });
      const csv = Papa.unparse([
        ["Error Message", "Error Cell"],
        ...convertedErrors,
      ]);
      const blob = new Blob([csv], { type: "text/csv" });
      setCsvDownload(blob);
    };
    if (errors.length > 2) {
      convertErrorsToCsv();
    }
  }, [errors]);

  const downloadCsv = () => {
    if (!csvDownload) return;
    const url = window.URL.createObjectURL(csvDownload);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}-errors.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isTypeError =
    !isLoading &&
    errors.length === 1 &&
    errors[0].message === "File type does not match selected type.";

  return (
    <>
      {isLoading && (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="error" />
          <Typography mt={2} fontSize="18px" fontWeight={500}>
            Checking and validating:
          </Typography>
          <Typography fontSize="18px" fontWeight={500}>
            {fileName}
          </Typography>
        </Stack>
      )}
      {!isLoading && Boolean(errors.length) && (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <ExclamationIcon>!</ExclamationIcon>
          <Typography
            my={2}
            fontSize="20px"
            fontWeight={500}
            width="501px"
            textAlign="center"
          >
            {isTypeError
              ? "The file you uploaded does not match the selected course."
              : errors.length <= 3
              ? "Oh no! We have found some errors in your file. Once the errors are resolved, resubmit your file to validate."
              : "Oh no! We have found some errors in your file. Download the errors sheet to review. Once the errors are resolved, resubmit your file to validate."}
          </Typography>
          {errors.length <= 3 && !isTypeError
            ? errors.map((error) => (
                <Typography
                  fontSize="19px"
                  fontWeight={600}
                  textAlign="center"
                  color={colors.orange}
                >
                  {error.message}{" "}
                  {error.cellLocation !== "N/A" && `Row ${error.cellLocation}.`}
                </Typography>
              ))
            : !isTypeError && (
                <DownloadButton variant="outlined" onClick={downloadCsv}>
                  <DownloadIcon /> Download Error Sheet
                </DownloadButton>
              )}
        </Stack>
      )}
    </>
  );
};

const DownloadButton = styled(Button)({
  margin: "1em",
  padding: "8px 15px",
  backgroundColor: colors.light,
  display: "flex",
  gap: "10px",
});

const ExclamationIcon = styled(Box)({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: colors.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: colors.light,
  fontSize: "30px",
});

export default Validate;
