import styled from "@emotion/styled";
import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import BaseModal from "shared/components/BaseModal";
import { colors } from "shared/styles/colors";
import FileDrop from "./FileDrop";
import StepIcon from "./StepIcon";
import Papa from "papaparse";
import { ValidationError } from "./types";
import Validate from "./Validate";
import { useCourseStore } from "stores/useCourseStore";
import Success from "./Success";
import produce from "immer";
import { useUserStore } from "stores/useUserStore";
import { validateLength } from "shared/utils";
import { CourseStatus } from "shared/types/generated-types";

interface Props {
  isOpen: boolean;
  grIdToReplace?: number;
  handleClose: () => void;
  type: string;
}

const UploadModal = ({
  isOpen,
  grIdToReplace,
  handleClose: close,
  type,
}: Props) => {
  const steps = ["Upload File", "Validate", "Success"];
  const [currentStep, setCurrentStep] = useState(0);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileErrors, setFileErrors] = useState<ValidationError[]>([]);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);

  const hasExtraDnaRows = useUserStore(
    (state) => state.user?.institution?.hasExtraDnaRows
  );
  const [selectedYear, selectedSeason] = useCourseStore((state) => [
    state.selectedYear,
    state.selectedSeason,
  ]);
  const yearId = selectedYear && selectedYear.id;

  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  const handleClose = () => {
    setCurrentStep(0);
    setFileErrors([]);
    setSelectedFile(null);
    close();
  };

  const requestController = new AbortController();

  const handleNextStep = async () => {
    switch (currentStep) {
      case 0: {
        selectedFile && validateCsv(selectedFile);
        break;
      }
      case 1: {
        if (isLoadingValidation) {
          requestController.abort();
          setIsLoadingValidation(false);
        }
        setCurrentStep((prevState) => prevState - 1);
        setFileErrors([]);
        setSelectedFile(null);
        break;
      }
      case 2: {
        handleClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  const validateType = (unformattedCsv: string[][]) => {
    let typeError = false;

    if (type !== "GR") {
      typeError = unformattedCsv[2][1] !== type;
    } else {
      typeError = unformattedCsv[2][0] !== ""; // GR will have a blank cell here
    }

    if (typeError) {
      return {
        message: "File type does not match selected type.",
        cellLocation: "N/A",
      };
    }

    return null;
  };

  const validateCsv = async (file: File) => {
    Papa.parse(file, {
      complete: async (results) => {
        setIsLoadingValidation(true);
        setCurrentStep((prevState) => prevState + 1);

        const stringArrResults = results.data as string[][];
        const filteredResults: string[][] = stringArrResults.filter((row) => {
          return row.every((cell) => cell === "") === false;
        });

        const lengthError = validateLength(filteredResults, type);
        if (lengthError) {
          setFileErrors([lengthError]);
          setIsLoadingValidation(false);
          return;
        }

        const typeError = validateType(filteredResults);
        if (typeError) {
          setFileErrors([typeError]);
          setIsLoadingValidation(false);
          return;
        }

        const { data: response } = await axios.post("/api/v1/csv", {
          csvData: filteredResults,
          yearId,
          yearStart: new Date(selectedYear?.yearStart).getFullYear(),
          yearEnd: new Date(selectedYear?.yearEnd).getFullYear(),
          selectedType: type,
          selectedSeason,
          hasExtraDnaRows,
          signal: requestController.signal,
          grIdToReplace,
        });
        setIsLoadingValidation(false);
        const { data, errors } = response;

        if (errors.length > 0) {
          setFileErrors(errors);
        } else {
          if (!user) return;

          const updatedUser = produce(user, (draftState) => {
            const year = draftState?.institution?.years?.find(
              (currYear) => currYear && currYear.id === yearId
            );
            if (!year) return;
            const period = year.periods.find(
              (currPeriod) => currPeriod && currPeriod.type === selectedSeason
            );
            if (!period) return;

            if (type === "GR") {
              if (grIdToReplace) {
                period.graduationAndRetention =
                  period.graduationAndRetention.filter(
                    (currGR) => currGR && currGR.id !== grIdToReplace
                  );
              }
              period.graduationAndRetention.push(data);
              period.status.GR = CourseStatus.Uploaded;
            } else {
              period.Course.push(data);
              period.status[type] = CourseStatus.Uploaded;
            }
          });

          setUser(updatedUser);
          setCurrentStep((prevState) => prevState + 1);
        }
      },
      error: (error) => {
        console.error("error", error);
      },
    });
  };

  const getButtonLabel = () => {
    switch (currentStep) {
      case 0: {
        return (
          <Typography fontWeight={500} color={colors.light}>
            Continue
          </Typography>
        );
      }
      case 1: {
        if (fileErrors.length > 0 && !isLoadingValidation) {
          return (
            <Typography fontWeight={500} color={colors.light}>
              Go back to Upload File
            </Typography>
          );
        } else {
          return (
            <Typography fontWeight={500} color={colors.red}>
              Cancel
            </Typography>
          );
        }
      }
      case 2: {
        return (
          <Typography fontWeight={500} color={colors.light}>
            Done
          </Typography>
        );
      }
      default: {
        return (
          <Typography fontWeight={500} color={colors.light}>
            Continue
          </Typography>
        ); // ! should never get here
      }
    }
  };

  return (
    <BaseModal
      open={isOpen}
      onClose={handleClose}
      width="900px"
      maxWidth="960px"
      height="621px"
      maxHeight="621px"
      noXPadding
    >
      <Title>
        {Boolean(grIdToReplace) ? "Replace" : "Upload"} Filled-in File
      </Title>
      <Subheader color={colors.lightPrimary}>
        Upload your file and after a successful validation your data will be
        submitted.
      </Subheader>

      <StepperContainer>
        <Stepper sx={{ mb: 1 }} activeStep={currentStep} connector={null}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>
                <Typography
                  sx={{
                    textDecoration:
                      currentStep === index ? "underline" : "none",
                  }}
                  color={currentStep <= index ? colors.primary : colors.red}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperContainer>

      {currentStep === 0 && (
        <FileDrop
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          errors={fileErrors}
        />
      )}

      {currentStep > 0 && (
        <ValidateAndSuccessContainer>
          {currentStep === 1 && selectedFile && (
            <Validate
              errors={fileErrors}
              isLoading={isLoadingValidation}
              fileName={selectedFile.name}
            />
          )}
          {currentStep === 2 && <Success />}
        </ValidateAndSuccessContainer>
      )}

      <Stack alignItems="center" mt={2}>
        {currentStep === 0 && (
          <Typography my={2} textAlign="center" color={colors.lightPrimary}>
            Make sure to save your filled-in template as a .csv file.
          </Typography>
        )}
        <ProgressButton
          onClick={handleNextStep}
          variant={
            currentStep === 1 && isLoadingValidation ? "outlined" : "contained"
          }
          addMargin={currentStep > 0}
        >
          {getButtonLabel()}
        </ProgressButton>
      </Stack>
    </BaseModal>
  );
};

const Title = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  marginTop: "1.5em",
});

const Subheader = styled(Typography)({
  fontSize: "20px",
  textAlign: "center",
  width: "50%",
  margin: "1em auto",
});

const StepperContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "1em",
});

const ProgressButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "addMargin",
})(({ addMargin }: { addMargin: boolean }) => ({
  width: "242px",
  height: "49px",
  padding: "10px 10px",
  marginTop: addMargin ? "1em" : "0",
  border: `2px solid ${colors.red}`,
  "&:hover": {
    border: `2px solid ${colors.red}`,
  },
}));

const ValidateAndSuccessContainer = styled(Box)({
  width: "100%",
  height: "243px",
  backgroundColor: colors.dropzone,
});

export default UploadModal;
