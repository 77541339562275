import { Accordion, AccordionSummary, styled, Typography } from "@mui/material";
import { ShowIcon } from "assets/icons/ShowIcon";
import ActiveStatus from "shared/components/ActiveStatus";
import { IntakeFormCompletedIndicator } from "shared/styledComponents";
import { colors } from "shared/styles/colors";
import { Status } from "shared/types/dataCollectionTypes";
import {
  AdminInstitution,
  AdminInstitutionStatus,
} from "shared/types/generated-types";
import { nonNullable } from "shared/utils";
import { Users } from "./Users";
import { DetailsButtons } from "./DetailsButtons";
import InstitutionCourseDetails from "./institutionCourseDetails/InstitutionCourseDetails";

interface Props {
  institution: AdminInstitution;
}

export const AdminInstitutionAccordion = ({ institution }: Props) => {
  const users = institution.users?.filter(nonNullable) ?? [];
  const hasUsers = Boolean(users && users.length);
  const userEmails = users.map((user) => user.email);

  const getStatus = (institution: AdminInstitution) => {
    switch (institution.status) {
      case AdminInstitutionStatus.DataUploaded: {
        return Status.Uploaded;
      }
      case AdminInstitutionStatus.PendingUpload: {
        return Status.Pending;
      }
      case AdminInstitutionStatus.PastDue: {
        return Status.PastDue;
      }
      default: {
        return Status.BeforePending;
      }
    }
  };

  return (
    <AdminAccordion disableGutters elevation={0} square key={institution.id}>
      <Summary>
        <IntakeFormCompletedIndicator
          isIntakeFormComplete={institution.intakeFormCompleted}
        />

        <ActiveStatus status={getStatus(institution)} />
        <InstitutionName title={institution.name}>
          {institution.name}
        </InstitutionName>

        <Text
          marginLeft="12px"
          fontStyle="italic"
          color={colors.secondaryLightGray}
        >
          {institution.statusText}
        </Text>

        <ShowIcon width={25} height={25} marginLeft={30} />
      </Summary>

      <InstitutionCourseDetails institution={institution} />

      {hasUsers && (
        <Users
          users={institution.users?.filter(nonNullable) ?? []}
          isIntakeFormComplete={institution.intakeFormCompleted}
        />
      )}

      <DetailsButtons
        hasUsers={hasUsers}
        userEmails={userEmails.filter(nonNullable)}
        institutionId={institution.id}
      />
    </AdminAccordion>
  );
};

const AdminAccordion = styled(Accordion)({
  border: `1px solid ${colors.divider}`,
  overflow: "hidden",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
});

const Summary = styled(AccordionSummary)({
  height: "50px",
  padding: 0,
  paddingLeft: 27,
  paddingRight: 30,

  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    justifyContent: "end",
  },
});

const Text = styled(Typography)({
  fontSize: "12px",
});

const InstitutionName = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
  marginRight: "auto",
  marginLeft: "16px",
  overflow: "hidden",
  maxWidth: "56ch",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  "@media (max-width: 1440px)": {
    maxWidth: "44ch",
  },

  "@media (max-width: 1300px)": {
    maxWidth: "33ch",
  },
});
