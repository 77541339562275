import styled from "@emotion/styled";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import BaseModal from "shared/components/BaseModal";
import { colors } from "shared/styles/colors";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleAccept: () => Promise<void>;
}

const TermsModal = ({ isOpen, handleClose, handleAccept }: Props) => {
  return (
    <BaseModal
      open={isOpen}
      onClose={handleClose}
      disableCloseButton
      width="50%"
      height="75%"
      maxWidth="655px"
      maxHeight="676px"
    >
      <Title>Accept Terms</Title>
      <Subheader color={colors.lightPrimary}>
        Make sure to read through our terms and privacy policy before entering
        the data collection portal.
      </Subheader>
      <TermsContainer>
        <Typography variant="body1">
          Statement of Commitment to Privacy and Confidentiality
        </Typography>
        <Typography variant="body1">
          Overview of the Project and Purpose of{" "}
          <Link
            href="https://cic.northeastern.edu/wp-content/uploads/sites/5/2023/01/CIC-Statement-of-Commitment-to-Privacy-and-Confidentiality_Spring-2023.pdf"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: colors.link, textDecoration: "underline" }}
          >
            this Statement
          </Link>
        </Typography>
        <Typography variant="body1">
          Housed at Northeastern's Khoury College of Computer Sciences, the
          Center for Inclusive Computing (the “Center”) awards funding to
          colleges and universities to scale best practices known to increase
          the representation of women in undergraduate computing. Collecting
          data for diagnostic and evaluation purposes is a critical aspect of
          our work and every grantee participates. The Center and Northeastern
          University recognize their obligation to use and safeguard the data
          collected in ways that respect confidentiality and privacy interests,
          as well as the integrity of the data. This Statement describes how we
          recognize these concerns and uphold our commitments to address them.
        </Typography>
        <Typography variant="body1">
          This Privacy Statement is intended to inform you of our policies and
          practices regarding our collection, use, and disclosure of the
          information that you submit to us. If you have any questions regarding
          this Statement, you can contact us at{" "}
          <a href="mailto:khoury-cic@northeastern.edu">
            khoury-cic@northeastern.edu
          </a>
          .
        </Typography>
      </TermsContainer>
      <Stack direction="row" justifyContent="center">
        <AcceptButton variant="contained" onClick={handleAccept}>
          Accept & Enter Site
        </AcceptButton>
      </Stack>
    </BaseModal>
  );
};

const Title = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  marginTop: "1em",
});

const Subheader = styled(Typography)({
  fontSize: "15px",
  textAlign: "center",
  width: "70%",
  margin: "1em auto",
});

const TermsContainer = styled(Box)({
  height: "45%",
  backgroundColor: colors.lightGray,
  overflowY: "auto",
  padding: "1em 2em",

  "& > p": {
    fontSize: "15px",
    marginTop: "1em",
  },
});

const AcceptButton = styled(Button)({
  backgroundColor: colors.primary,
  boxShadow: "none",
  textTransform: "none",
  padding: "5px 30px",
  fontSize: "16px",
  marginTop: "20px",

  "&:hover": {
    backgroundColor: colors.primary,
    boxShadow: "none",
  },
});

export default TermsModal;
