import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { colors } from "shared/styles/colors";

export enum FilterOptions {
  DataUploaded = "Data Uploaded",
  PendingUploads = "Pending Uploads",
  PastDue = "Past Due",
  ShowAll = "Show All",
}

interface Props {
  selectedFilter: FilterOptions;
  handleFilterChange: (event: SelectChangeEvent) => void;
}

export const Filter = ({ selectedFilter, handleFilterChange }: Props) => {
  return (
    <SelectInput
      value={selectedFilter}
      onChange={(event) => handleFilterChange(event as SelectChangeEvent)}
      MenuProps={{
        sx: {
          "&& .Mui-selected": {
            backgroundColor: colors.paleRed,
          },
          "&& .Mui-selected.Mui-focusVisible": {
            backgroundColor: colors.paleRed,
          },
        },
      }}
    >
      <Subheader>Filter by:</Subheader>

      {Object.values(FilterOptions).map((option) => (
        <SelectOption key={option} value={option}>
          {option}
        </SelectOption>
      ))}
    </SelectInput>
  );
};

const SelectInput = styled(Select)({
  borderRadius: "15px",
  border: "2px solid black",
  height: "31px",
  width: "142px",

  ".MuiInputBase-input, .MuiInputBase-root": {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
  },

  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  ".MuiSvgIcon-root": {
    color: "black",
  },
});

const Subheader = styled(ListSubheader)({
  fontSize: "15px",
  fontWeight: "600",
  lineHeight: "37px",
  color: "black",
});

const SelectOption = styled(MenuItem)({
  fontSize: "12px",
  lineHeight: "37px",
  fontWeight: 500,
});
