import {
  Alert,
  Box,
  IconButton,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "assets/icons/DeleteIcon";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { colors } from "shared/styles/colors";
import { ValidationError } from "./types";

interface Props {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  errors: ValidationError[];
}

const FileDrop = ({ selectedFile, setSelectedFile, errors }: Props) => {
  const [fileNotCsv, setFileNotCsv] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // * This useEffect and selectedFile state is necessary because acceptedFiles resets immediately when the finder is opened
    if (acceptedFiles.length === 0) return;

    if (!acceptedFiles[0].name.endsWith(".csv")) {
      setFileNotCsv(true);
    } else {
      setSelectedFile(acceptedFiles[0]);
    }
  }, [acceptedFiles, setSelectedFile]);

  useEffect(() => {
    if (errors.length > 0 && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [errors, setSelectedFile]);

  const deleteFile = () => setSelectedFile(null);

  return (
    <DropzoneContainer
      {...getRootProps({ className: "dropzone" })}
      onClick={() => inputRef.current?.click()} // * This is necessary to open the file explorer in non-Chrome browsers
    >
      <input {...getInputProps()} ref={inputRef} />
      <Box mt={selectedFile ? 9 : 12}>
        {selectedFile && (
          <Box
            onClick={(e) => e.stopPropagation()}
            textAlign="center"
            fontWeight={700}
          >
            <span>{selectedFile.name}</span>
            <IconButton onClick={deleteFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <DropzoneText>
          Drag and drop the file or <DropzoneLink>Browse for File</DropzoneLink>{" "}
          to replace
        </DropzoneText>
      </Box>

      <Snackbar
        open={fileNotCsv}
        autoHideDuration={6000}
        onClose={() => {
          setFileNotCsv(false);
        }}
      >
        <Alert severity={"error"} sx={{ width: "100%" }}>
          The file must be a .csv
        </Alert>
      </Snackbar>
    </DropzoneContainer>
  );
};

const DropzoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  padding: "0px 50px",
  width: "45%",
  height: "220px",
  margin: "auto",
  backgroundColor: colors.dropzone,
}));

const DropzoneText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  textAlign: "center",
  fontSize: "14px",
  marginTop: "1em",
  cursor: "pointer",
}));

const DropzoneLink = styled("span")({
  color: colors.red,
  textDecoration: "underline",
  fontWeight: 800,
});

export default FileDrop;
