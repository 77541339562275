interface Props {
  width?: number;
  height?: number;
  marginLeft?: number;
}

export const DownloadIcon = ({
  width = 36,
  height = 36,
  marginLeft = 0,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      style={{ maxWidth: width, maxHeight: height, marginLeft: marginLeft }}
    >
      <circle cx="18" cy="18" r="18" fill="#fff" />
      <g fill="#B73235" stroke="#B73235" strokeWidth=".5" clipPath="url(#a)">
        <path d="M9.594 19.756a.594.594 0 0 1 .594.594v2.969a1.187 1.187 0 0 0 1.187 1.187h14.25a1.188 1.188 0 0 0 1.188-1.187V20.35a.594.594 0 0 1 1.187 0v2.969a2.375 2.375 0 0 1-2.375 2.375h-14.25A2.375 2.375 0 0 1 9 23.319V20.35a.594.594 0 0 1 .594-.594z" />
        <path d="M18.08 22.077a.594.594 0 0 0 .84 0l3.563-3.563a.594.594 0 1 0-.841-.84l-2.549 2.549V9.78a.594.594 0 1 0-1.187 0v10.442l-2.548-2.55a.594.594 0 1 0-.841.841l3.562 3.563z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M9 8h19v19H9z" />
        </clipPath>
      </defs>
    </svg>
  );
};
