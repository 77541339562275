import { useState } from "react";
import {
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { CourseType } from "shared/constants";
import { SelectTemplateModal } from "../components/SelectTemplateModal";
import { StepOneIcon } from "assets/icons/StepOneIcon";
import { StepTwoIcon } from "assets/icons/StepTwoIcon";
import { StepThreeIcon } from "assets/icons/StepThreeIcon";

const UploadInstructions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(CourseType.CS1);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedCourse(event.target.value as CourseType);
  };

  return (
    <>
      <Stack padding="10px 37px 44px">
        <Typography variant="h4" fontSize="25px" marginBottom="26px">
          Upload Instructions
        </Typography>

        <Stack spacing={2} direction="row" marginBottom="20px">
          <Box>
            <StepOneIcon />
          </Box>

          <Text variant="body1" color="text.secondary">
            Download the correct course file.
          </Text>
        </Stack>

        <SelectTemplateButton
          onClick={() => setIsModalOpen(true)}
          variant="contained"
        >
          Select Course Template File
        </SelectTemplateButton>

        <Stack spacing={2} direction="row" marginBottom="38px">
          <Box>
            <StepTwoIcon />
          </Box>

          <Text variant="body1" color="text.secondary">
            Add your data to the template file (if working on excel make sure to
            export or save as a .csv file).
          </Text>
        </Stack>

        <Stack spacing={2} direction="row">
          <Box>
            <StepThreeIcon />
          </Box>

          <Text variant="body1" color="text.secondary">
            Find the correct school period and course and upload your filled-in
            file. Once validated, submit to complete the process.
          </Text>
        </Stack>
      </Stack>

      <SelectTemplateModal
        isModalOpen={isModalOpen}
        selectedCourse={selectedCourse}
        setIsModalOpen={setIsModalOpen}
        setSelectedCourse={setSelectedCourse}
        handleChange={handleChange}
      />
    </>
  );
};

const Text = styled(Typography)({
  fontSize: "18px",
  lineHeight: "normal",
});

const SelectTemplateButton = styled(Button)({
  height: "49px",
  borderRadius: "10px",
  marginBottom: "40px",
});

export default UploadInstructions;
