import { Button, Stack, styled, Typography } from "@mui/material";
import BaseModal from "shared/components/BaseModal";

interface Props {
  fileName: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const FileDeletionModal = ({ fileName, isOpen, onClose, onDelete }: Props) => {
  return (
    <BaseModal open={isOpen} onClose={onClose} width="438px" height="384px">
      <Title variant="h1">Delete File</Title>
      <Subheader variant="h3">Are you sure you want to delete:</Subheader>
      <Subheader fontWeight={600} variant="h3">
        {fileName || "Retention and Graduation"}?
      </Subheader>
      <StyledStack>
        <Button fullWidth variant="contained" onClick={onDelete}>
          Delete
        </Button>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Nevermind
        </Button>
      </StyledStack>
    </BaseModal>
  );
};

const Title = styled(Typography)({
  fontSize: "25px",
  textAlign: "center",
  marginTop: "3em",
});

const Subheader = styled(Typography)({
  fontSize: "15px",
  textAlign: "center",
  margin: "1em auto",
});

const StyledStack = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  width: "200px",
  margin: "auto",
  marginTop: "30px",
});

export default FileDeletionModal;
