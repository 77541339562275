import { AccordionDetails, Button, Link, styled } from "@mui/material";
import { ExternalLink } from "assets/icons/ExternalLink";
import { colors } from "shared/styles/colors";
import { AUTH0_DASHBOARD_URL, FOREST_ADMIN_URL, NODE_ENV } from "shared/config";
import { useCopyEmails } from "shared/hooks/useCopyEmails";
import CopiedToClipboardToast from "shared/components/CopiedToClipboardToast";

interface Props {
  hasUsers: boolean;
  userEmails: string[];
  institutionId: number;
}

export const DetailsButtons = ({
  hasUsers,
  userEmails,
  institutionId,
}: Props) => {
  const {
    handleCopyEmails,
    copiedToClipboardStatus,
    setCopiedToClipboardStatus,
  } = useCopyEmails(userEmails);

  return (
    <>
      <Details>
        <Link
          href={`${FOREST_ADMIN_URL}/NU%20CIC/Production/Admins/data/Institution/index/record/Institution/${institutionId}/details`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DetailsButton
            variant="outlined"
            endIcon={<ExternalLink color={colors.red} />}
          >
            Edit School
          </DetailsButton>
        </Link>

        <Link
          href={`${AUTH0_DASHBOARD_URL}/dashboard/us/${
            NODE_ENV === "production" ? "nu-cic-prod" : "nu-cic"
          }/users`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DetailsButton
            variant="outlined"
            endIcon={<ExternalLink color={colors.red} />}
          >
            Add User
          </DetailsButton>
        </Link>

        {hasUsers && (
          <>
            <Link
              href={`${FOREST_ADMIN_URL}/NU%20CIC/Production/Admins/data/User/index?filter=%7B%22type%22%3A%22and%22%2C%22conditions%22%3A%5B%7B%22operator%22%3A%22is%22%2C%22value%22%3A${institutionId}%2C%22fieldName%22%3A%22institution%22%2C%22subFieldName%22%3A%22id%22%2C%22embeddedFieldName%22%3Anull%7D%5D%7D`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DetailsButton
                variant="outlined"
                endIcon={<ExternalLink color={colors.red} />}
              >
                Edit User(s)
              </DetailsButton>
            </Link>
            <DetailsButton variant="outlined" onClick={handleCopyEmails}>
              Copy All Email(s)
            </DetailsButton>
          </>
        )}
      </Details>

      <CopiedToClipboardToast
        copiedToClipboardStatus={copiedToClipboardStatus}
        setCopiedToClipboardStatus={setCopiedToClipboardStatus}
      />
    </>
  );
};

const Details = styled(AccordionDetails)({
  display: "flex",
  backgroundColor: colors.accordionDetailsBackground,
  border: `1px solid ${colors.border}`,
  gap: "22px",
  justifyContent: "center",
  padding: "26px 0",
});

const DetailsButton = styled(Button)({
  backgroundColor: colors.light,
  border: `2px solid ${colors.red}`,
  borderRadius: "100px",
  color: colors.red,
  padding: "3px 16px",

  "&:hover": {
    backgroundColor: colors.light,
    border: `2px solid ${colors.red}`,
  },
});
