import { Box, Stack, styled, Typography } from "@mui/material";
import { DataUploaded } from "assets/icons/DataUploaded";
import { PastDue } from "assets/icons/PastDue";
import { PendingUpload } from "assets/icons/PendingUpload";
import { colors } from "shared/styles/colors";
import ActiveStatus from "./ActiveStatus";
import { Status } from "shared/types/dataCollectionTypes";

interface Props {
  isAdminPage: boolean;
}

const StatusLegend = ({ isAdminPage }: Props) => {
  return (
    <Container>
      <LegendContainer>
        <DataUploaded />
        <LegendText>Data Uploaded/Bypassed</LegendText>
      </LegendContainer>

      <LegendContainer>
        <PendingUpload />
        <LegendText>Pending Upload</LegendText>
      </LegendContainer>

      <LegendContainer>
        <PastDue />
        <LegendText>Past Due</LegendText>
      </LegendContainer>

      {isAdminPage && (
        <LegendContainer>
          <IncompleteIntakeForm />
          <LegendText>Incomplete Intake Form</LegendText>
        </LegendContainer>
      )}

      <LegendContainer>
        <ActiveStatus status={Status.Historical} />
        <LegendText>Optional</LegendText>
      </LegendContainer>
    </Container>
  );
};

export default StatusLegend;

const Container = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1rem",
  paddingBottom: "18px",
});

const LegendContainer = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "4px",
});

const LegendText = styled(Typography)({
  fontSize: "12px",
  color: colors.secondaryLightGray,
});

const IncompleteIntakeForm = styled(Box)({
  width: "6px",
  height: "20px",
  backgroundColor: colors.purple,
});
