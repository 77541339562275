import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { colors } from "shared/styles/colors";
import { Status } from "shared/types/dataCollectionTypes";
import { Course as CourseType, Seasons } from "shared/types/generated-types";
import { useCourseStore } from "stores/useCourseStore";
import File from "./File";
import UploadModal from "./uploadModal/UploadModal";

interface Props {
  type: string;
  seasonType: Seasons;
  courses?: CourseType[];
  status: Status;
  periodId: number;
}

const Course = ({
  courses = [],
  status,
  type,
  seasonType,
  periodId,
}: Props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [setSelectedSeason, setSelectedCourse] = useCourseStore((state) => [
    state.setSelectedSeason,
    state.setSelectedCourse,
  ]);

  const isReplacing = type === "GR" && courses.length > 0;

  const closeModal = () => setIsUploadModalOpen(false);
  const openModal = () => {
    if (!courses) return;
    setSelectedCourse(type);
    setSelectedSeason(seasonType);
    setIsUploadModalOpen(true);
  };

  const pluralize = courses.length > 1 ? "(s)" : "";
  const uploadPluralize = courses.length > 0 ? "(s)" : "";

  return (
    <OuterContainer>
      <InnerContainer>
        <Grid
          container
          justifyContent={
            status === Status.PastDue ? "flex-start" : "space-around"
          }
        >
          <Grid item xs={12} sm={6} padding="15px 15px 0">
            {/* //TODO Retention and Graduation text should not break onto two lines */}
            <Typography fontSize="16px" color={colors.primary} fontWeight="600">
              {type === "GR"
                ? "Retention & Graduation"
                : `${type} Course${pluralize}`}
              :
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            sm={6}
            padding="15px 15px 0"
          >
            <RoundButton onClick={openModal} variant="contained">
              <Typography fontSize="14px" color={colors.light}>
                {isReplacing ? `Replace File` : `Upload File${uploadPluralize}`}
              </Typography>
            </RoundButton>
          </Grid>
        </Grid>
        {courses.length ? (
          courses.map((file) => (
            <File
              file={file}
              status={status}
              type={type}
              periodId={periodId}
              seasonType={seasonType}
            />
          ))
        ) : (
          <File
            file={null}
            status={status}
            type={type}
            periodId={periodId}
            seasonType={seasonType}
          />
        )}
      </InnerContainer>

      <UploadModal
        isOpen={isUploadModalOpen}
        grIdToReplace={isReplacing ? courses[0]?.id : undefined}
        handleClose={closeModal}
        type={type}
      />
    </OuterContainer>
  );
};

const OuterContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const InnerContainer = styled.div({
  backgroundColor: "white",
  borderRadius: "10px",
  border: `1px solid ${colors.border}`,
  width: "90%",
  height: "100%",
});

const RoundButton = styled(Button)({
  height: "34px",
  width: "100px",
  backgroundColor: "black",
  borderRadius: "100px",
  whiteSpace: "nowrap",
  textAlign: "center",

  "&:hover": {
    backgroundColor: "black",
    opacity: "0.87",
  },
});

export default Course;
