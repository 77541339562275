import HomePage from "./modules/homePage/HomePage";

interface Route {
  path: string; // TODO enum for paths
  element: JSX.Element;
  // TODO privateRoute, flag properties?
}

export const routes: Route[] = [
  {
    path: "/",
    element: <HomePage />,
  },
];
