import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { DropdownIcon } from "assets/icons/DropdownIcon";
import { Dispatch, SetStateAction } from "react";
import BaseModal from "shared/components/BaseModal";
import { CourseType } from "shared/constants";
import { colors } from "shared/styles/colors";
import { TemplateDownloadButton } from "./DownloadButton";

interface Props {
  isModalOpen: boolean;
  selectedCourse: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedCourse: Dispatch<SetStateAction<CourseType>>;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
}

export const SelectTemplateModal = ({
  isModalOpen,
  selectedCourse,
  setIsModalOpen,
  setSelectedCourse,
  handleChange,
}: Props) => {
  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedCourse(CourseType.CS1);
  };

  return (
    <BaseModal
      open={isModalOpen}
      onClose={handleClose}
      width={640}
      height={616}
      maxHeight={616}
    >
      <Stack
        direction="column"
        textAlign="center"
        paddingTop="60px"
        alignItems="center"
      >
        <Typography variant="h2" fontSize="30px" marginBottom="20px">
          Select your Template
        </Typography>
        <Typography
          variant="body1"
          fontSize="20px"
          maxWidth="460px"
          lineHeight="normal"
          marginBottom="42px"
        >
          Select the correct course and download the template file. Once ready,
          fill the file with data, save or import as a .csv and come back to
          upload.
        </Typography>

        <SelectContainer>
          <SelectLabel htmlFor="select-course">Select a course:</SelectLabel>
          <SelectInput
            value={selectedCourse}
            onChange={handleChange}
            labelId="select-course"
            IconComponent={DropdownIcon}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: colors.paleRed,
                },
                "&& .Mui-selected.Mui-focusVisible": {
                  backgroundColor: colors.paleRed,
                },
              },
            }}
          >
            {Object.values(CourseType).map((course) => (
              <MenuItem key={course} value={course}>
                {course}
              </MenuItem>
            ))}
          </SelectInput>
        </SelectContainer>

        <TemplateDownloadButton selectedCourse={selectedCourse} />

        <DoneButton variant="contained" onClick={handleClose}>
          Done
        </DoneButton>
      </Stack>
    </BaseModal>
  );
};

const SelectContainer = styled(FormControl)({
  marginBottom: "44px",
});

const SelectLabel = styled("label")({
  fontFamily: "Lato",
  fontWeight: 500,
  textAlign: "left",
  marginBottom: "8px",
});

const SelectInput = styled(Select)({
  width: "393px",
  height: "54px",
  fontWeight: 600,
  textAlign: "left",
});

const DoneButton = styled(Button)({
  backgroundColor: colors.darkOffBlack,
  borderRadius: "10px",
  padding: "12px 105px",
  "&:hover": {
    backgroundColor: colors.darkOffBlack,
    opacity: "0.87",
  },
});
