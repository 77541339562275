import styled from "@emotion/styled";
import { Box, MenuItem, Select, Stack, Tabs, Typography } from "@mui/material";
import { colors } from "shared/styles/colors";
import { useEffect, useState } from "react";
import Period from "./Period";
import { quarters, semesters } from "shared/data/periods";
import { DataPeriod } from "shared/types/dataCollectionTypes";
import DataCollectionSkeleton from "./DataCollectionSkeleton";
import StatusLegend from "shared/components/StatusLegend";
import { ContactSupport } from "shared/components/ContactSupport";
import { useUserStore } from "stores/useUserStore";
import { useCourseStore } from "stores/useCourseStore";
import { Seasons, Year } from "shared/types/generated-types";
import { Status } from "shared/types/dataCollectionTypes";
import { getPeriodStatus } from "shared/utils";
import ActiveStatus from "shared/components/ActiveStatus";
interface Props {
  isLoading: boolean;
}

const DataCollection = ({ isLoading }: Props) => {
  const [expandedAccordion, setExpandedAccordion] = useState("");
  const [year, setYear] = useState<Year | null>(null);
  const [setSelectedYear, setSelectedSeason] = useCourseStore((state) => [
    state.setSelectedYear,
    state.setSelectedSeason,
  ]);

  const user = useUserStore((state) => state.user);

  const sortedYears =
    user && user.institution && user.institution.years
      ? user.institution.years
          .filter((year): year is Year => Boolean(year))
          .map((year) => {
            let yearStatus = Status.BeforePending;
            const periodStatuses = year.periods
              .filter((period) => period.type !== Seasons.Summer)
              .map((period) => getPeriodStatus(period.status));

            if (periodStatuses.includes(Status.PastDue)) {
              yearStatus = Status.PastDue;
            } else if (periodStatuses.includes(Status.Pending)) {
              yearStatus = Status.Pending;
            } else if (
              periodStatuses
                .filter((status) => status !== Status.Historical)
                .every((status) => status === Status.Uploaded) ||
              periodStatuses.every((status) => status === Status.Historical)
            ) {
              yearStatus = Status.Uploaded;
            }

            return { ...year, status: yearStatus };
          })
          .sort((a, b) => b?.yearStart - a?.yearStart)
      : [];

  useEffect(() => {
    if (!year && sortedYears?.length) {
      setYear(sortedYears[0]);
    }
  }, [sortedYears]);

  useEffect(() => {
    if (user && year) {
      const updatedYear = user.institution?.years?.find(
        (currYear) => currYear?.id === year.id
      );
      if (updatedYear) setYear(updatedYear);
    }
  }, [user]);

  const institution = user?.institution;

  const emptyPeriods: DataPeriod[] = institution?.usesQuarters
    ? quarters
    : semesters;

  const yearPeriods = year?.periods || [];

  const currentData = emptyPeriods.map((emptyPeriod) => {
    const periodData = yearPeriods?.find(
      (currPeriodData) => currPeriodData.type === emptyPeriod.type
    );
    if (!periodData) {
      return { ...emptyPeriod, disabled: true };
    }

    const populatedCourses = structuredClone(emptyPeriod.courses);
    for (const courseType of Object.keys(populatedCourses)) {
      populatedCourses[courseType].status = periodData.status[courseType];

      if (courseType === "GR") {
        populatedCourses.GR.data = periodData?.graduationAndRetention;
        if (Boolean(populatedCourses.GR.data.length)) {
          populatedCourses.GR.status = Status.Uploaded;
        }
      } else {
        const courses = periodData?.Course.filter(
          (course) => course.type === courseType
        );
        if (Boolean(courses.length)) {
          populatedCourses[courseType].data.push(...courses);
          populatedCourses[courseType].status = Status.Uploaded;
        }
      }
    }

    const status = getPeriodStatus(periodData.status);

    return {
      ...emptyPeriod,
      periodId: periodData.id,
      courses: populatedCourses,
      status,
    } as DataPeriod;
  });

  useEffect(() => {
    if (year) {
      setSelectedYear(year);
    }
  }, [year]);

  const handleYearChange = (newYear: Year) => {
    setYear(newYear);
    setExpandedAccordion("");
  };

  const handleAccordionChange =
    (period: string, seasonType: Seasons) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setSelectedSeason(seasonType);
      setExpandedAccordion(isExpanded ? period : "");
    };

  return (
    <Box>
      {!isLoading && currentData ? (
        <>
          <Container padding="0 27px">
            <Typography variant="h4" fontSize="25px" marginBottom="18px">
              Data Portfolio
            </Typography>
            <StatusLegend isAdminPage={false} />
          </Container>

          {institution && (
            <Container padding="0 27px" mb="20px">
              <Typography variant="h5" fontSize="20px" maxWidth="50%">
                {institution.name}
              </Typography>
              <YearSelect
                variant="outlined"
                value={new Date(year?.yearStart).getFullYear()}
              >
                {sortedYears?.map((year) => (
                  <MenuItem
                    value={new Date(year?.yearStart).getFullYear()}
                    key={year?.id}
                    onClick={() => handleYearChange(year as Year)}
                  >
                    <Stack direction="row" alignItems="center" gap="10px">
                      <ActiveStatus status={year.status} />
                      {`${new Date(year?.yearStart).getFullYear()} -
                      ${new Date(year?.yearStart).getFullYear() + 1}`}
                    </Stack>
                  </MenuItem>
                ))}
              </YearSelect>
            </Container>
          )}

          <Box>
            <Stack spacing={2}>
              {year &&
                currentData &&
                currentData.map(
                  ({ courses, id, periodId, type, disabled, status }) => (
                    <Period
                      courses={courses}
                      usesQuarters={institution?.usesQuarters}
                      handleChange={handleAccordionChange}
                      key={id}
                      id={id}
                      periodId={periodId}
                      type={type}
                      isExpanded={expandedAccordion === id}
                      yearStart={year?.yearStart}
                      status={status}
                      disabled={disabled}
                    />
                  )
                )}
            </Stack>
          </Box>

          <SupportContainer>
            <ContactSupport fontSize="15px" />
          </SupportContainer>
        </>
      ) : (
        <DataCollectionSkeleton />
      )}
    </Box>
  );
};

const Container = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1rem",
});

const TabsContainer = styled(Box)({
  width: "100%",
  borderBottom: "1px solid divider",
});

const RedTabs = styled(Tabs)({
  paddingLeft: "10px",

  "& .MuiTabs-indicator": {
    backgroundColor: colors.red,
    height: "3px",
  },
  "& .MuiTab-root": {
    fontSize: "12px",
    color: colors.primary,
    "&.Mui-selected": {
      color: colors.red,
      fontWeight: 900,
    },
  },
  "button.MuiButtonBase-root": {
    paddingBottom: 0,
  },
});

const SupportContainer = styled(Box)({
  paddingLeft: "28px",
  marginTop: "45px",
});

const YearSelect = styled(Select)({
  width: "160px",
  height: "33px",
  borderRadius: "10px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.primary,
  },
  "& .MuiMenuItem-root": {
    fontSize: "12px",
  },
});

export default DataCollection;
