interface Props {
  width?: number;
  height?: number;
}

export const SearchIcon = ({ width = 18, height = 18 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      style={{ minWidth: width, minHeight: height }}
    >
      <path
        fill="#6C727E"
        d="m17.71 16.29-3.4-3.39A7.92 7.92 0 0 0 16 8a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1.002 1.002 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095zM2 8a6 6 0 1 1 12 0A6 6 0 0 1 2 8z"
      />
    </svg>
  );
};
