import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Button, Checkbox, Link, Stack, Typography } from "@mui/material";
import { ExternalLink } from "assets/icons/ExternalLink";
import { useEffect, useState } from "react";
import Logo from "shared/components/Logo";
import { useGetUser } from "shared/hooks/useGetUser";
import { CHANGE_INTAKE_FORM_COMPLETED } from "shared/queries/queries";
import { colors } from "shared/styles/colors";
import { useUserStore } from "stores/useUserStore";

interface Props {
  isAdmin?: boolean;
  isLoggedIn?: boolean;
  isLoading?: boolean;
}

const HomeHeader = ({ isLoggedIn, isAdmin, isLoading }: Props) => {
  const [intakeFormComplete, setIntakeFormComplete] = useState(false);
  const [loadingMutation, setLoadingMutation] = useState(false);

  const { user: auth0User } = useAuth0();
  const { user } = useGetUser({ auth0Id: auth0User?.sub, isLoggedIn });
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    if (user) {
      setUser(user);
      user?.institution &&
        setIntakeFormComplete(user.institution.intakeFormCompleted);
    }
  }, [user]);

  const [completeIntakeForm] = useMutation(CHANGE_INTAKE_FORM_COMPLETED, {
    variables: {
      input: {
        intakeFormComplete: !intakeFormComplete,
      },
    },
  });

  const handleClick = async () => {
    setLoadingMutation(true);
    try {
      await completeIntakeForm();
      setIntakeFormComplete((prevState) => !prevState);
    } catch (error) {
      console.error(error);
    }
    setLoadingMutation(false);
  };

  return (
    <HeaderStack
      borderBottom={isLoggedIn || isLoading ? `1px solid ${colors.red}` : ""}
    >
      <Logo />
      {isLoggedIn ? (
        <Stack direction="row" alignItems="center" spacing={6}>
          {!isAdmin && (
            <IntakeFormContainer direction="row" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="body1" fontWeight={800}>
                  Intake Form:
                </Typography>
                <Link
                  href="https://neu.co1.qualtrics.com/jfe/form/SV_e4dw5YRh9HVf3E2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ViewFormButton variant="contained" LinkComponent={Link}>
                    <Typography sx={{ marginRight: "8px" }}>View</Typography>
                    <ExternalLink color={colors.light} />
                  </ViewFormButton>
                </Link>
              </Stack>

              <Stack direction="row" alignItems="center">
                <Checkbox
                  checked={intakeFormComplete}
                  disabled={loadingMutation || !user}
                  onChange={handleClick}
                />
                <MarkAsCompleted variant="body2" color="text.secondary">
                  Mark form as completed
                </MarkAsCompleted>
              </Stack>
            </IntakeFormContainer>
          )}

          <Link
            href="https://tableau.northeastern.edu/t/CenterforInclusiveComputing/views/CenterforInclusiveComputingDataVisualizationDashboards/StartHere?%3Aembed=y&%3AdataDetails=no&%3Aalerts=no&%3AshowAppBanner=false&%3AshowAskData=false&%3Atoolbar=no&%3Aiid=1&%3AisGuestRedirectFromVizportal=y#3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableauButton variant="outlined">
              <TableauText>View Data in Tableau</TableauText>
              <ExternalLink width={16} height={16} />
            </TableauButton>
          </Link>
        </Stack>
      ) : (
        <Typography>
          Need Help? <ContactLink>Contact CIC</ContactLink>
        </Typography>
      )}
    </HeaderStack>
  );
};

const TableauButton = styled(Button)({
  border: "2px solid",
  maxHeight: "40px",
  "&:hover": {
    border: "2px solid",
  },
});

const TableauText = styled(Typography)({
  fontWeight: "bold",
  marginRight: "0.5rem",
});

const HeaderStack = styled(Stack)({
  backgroundColor: colors.light,
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  padding: "1rem 2rem 0 2rem",
});

const ContactLink = styled(Link)`
  text-decoration: none;
  color: #3f7293;
  font-weight: bold;
  margin-left: 0.5em;
`;

const MarkAsCompleted = styled(Typography)({
  fontStyle: "italic",
});

const ViewFormButton = styled(Button)({
  backgroundColor: colors.offBlack,
  color: colors.light,
  fontSize: "16px",
  width: "110px",
  "&:hover": {
    backgroundColor: colors.offBlack,
  },
});

const IntakeFormContainer = styled(Stack)({
  padding: "10px 2rem",
  backgroundColor: "#f6f6f6",
  borderRadius: "0.5rem",
});

export default HomeHeader;
