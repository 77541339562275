import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GRAPHQL_URI, NODE_ENV } from "shared/config";

export const useClient = (token?: string) => {
  return new ApolloClient({
    uri: GRAPHQL_URI,
    cache: new InMemoryCache(),
    connectToDevTools: NODE_ENV !== "production",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
};
