export const colors = {
  primary: "#000000",
  lightPrimary: "#414141",
  light: "#ffffff",
  lightGray: "#f2f2f2",
  dropzone: "#f3f3f3",
  boxShadow: "rgba(175, 175, 175, 0.25)",
  secondaryLightGray: "#575757",
  red: "#b73235",
  paleRed: "#ffd9da",
  orange: "#ff0000",
  dashboardBackground: "#f6f6f6",
  accordionDetailsBackground: "#ededed",
  border: "#d8d8d8",
  divider: "#e7e7e7",
  link: "#006cb2",
  offBlack: "#353535",
  darkOffBlack: "#1d1d1d",
  darkerOffBlack: "#101010",
  purple: "#9a2c82",
  placeholder: "#65748b",
  babyBlue: "#a8e5ff",
};
