interface Props {
  width?: number;
  height?: number;
}

export const PendingUpload = ({ width = 18, height = 18 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
    >
      <rect width="18" height="18" rx="9" fill="#F7E866" />
      <path
        d="M6.494 5.479c.143-.13.299-.252.468-.365a3.282 3.282 0 0 1 1.19-.5c.234-.052.485-.078.754-.078.36 0 .688.052.987.156.304.1.564.245.78.436.222.186.393.413.514.682.121.264.182.561.182.89 0 .33-.048.616-.143.859a2.317 2.317 0 0 1-.364.623 3.06 3.06 0 0 1-.481.468l-.494.371a3.189 3.189 0 0 0-.396.345.691.691 0 0 0-.195.377l-.137.974H8.21l-.098-1.072a.838.838 0 0 1 .098-.527c.087-.147.201-.286.345-.415a4.7 4.7 0 0 1 .48-.371 3.71 3.71 0 0 0 .495-.403c.151-.147.279-.312.383-.494.104-.182.156-.4.156-.656a1.031 1.031 0 0 0-.37-.806 1.284 1.284 0 0 0-.41-.221 1.594 1.594 0 0 0-.507-.079c-.256 0-.472.03-.65.091-.178.061-.33.128-.455.202a2.87 2.87 0 0 0-.312.195.395.395 0 0 1-.208.085.32.32 0 0 1-.299-.176l-.364-.591zm1.248 7.715c0-.126.022-.243.065-.351a.97.97 0 0 1 .188-.293.907.907 0 0 1 .995-.195.911.911 0 0 1 .487.488.861.861 0 0 1 .072.351.878.878 0 0 1-.266.643.83.83 0 0 1-.293.189.907.907 0 0 1-.709 0 .843.843 0 0 1-.474-.475.954.954 0 0 1-.065-.357z"
        fill="#000"
      />
    </svg>
  );
};
