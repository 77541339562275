import { User } from "shared/types/generated-types";
import { UserStore } from "stores/types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useUserStore = create<UserStore>()(
  devtools((set) => ({
    user: null,
    setUser: (user: User) => set({ user }),
  }))
);
