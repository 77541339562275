import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { DataUploaded } from "assets/icons/DataUploaded";
import { PastDue } from "assets/icons/PastDue";
import { PendingUpload } from "assets/icons/PendingUpload";
import { colors } from "shared/styles/colors";
import { Status } from "shared/types/dataCollectionTypes";

const ActiveStatus = ({ status }: { status: Status | string }) => {
  const getActiveIcon = () => {
    switch (status) {
      case Status.Uploaded: {
        return <DataUploaded />;
      }
      case Status.Pending: {
        return <PendingUpload />;
      }
      case Status.PastDue: {
        return <PastDue />;
      }
      case Status.NotRequired:
      case Status.Historical: {
        return <OptionalBabyBlueCircle />;
      }
      default: {
        return <ClosedGrayCircle />;
      }
    }
  };

  return getActiveIcon();
};

const ClosedGrayCircle = styled(Box)({
  width: "18px",
  height: "18px",
  minWidth: "18px",
  minHeight: "18px",
  borderRadius: "50%",
  backgroundColor: colors.divider,
});

const OptionalBabyBlueCircle = styled(Box)({
  width: "18px",
  height: "18px",
  minWidth: "18px",
  minHeight: "18px",
  borderRadius: "50%",
  backgroundColor: colors.babyBlue,
});

export default ActiveStatus;
