interface Props {
  width?: number;
  height?: number;
}

export const DropdownIcon = ({ width = 22, height = 11, ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      style={{
        maxWidth: width,
        maxHeight: height,
        marginRight: "10px",
        marginTop: "4px",
      }}
      // Spreading props onto the svg element passes
      // MUI's default transform onto the icon
      {...props}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 10 9 10-9"
      />
    </svg>
  );
};
