import { ApolloError, useQuery } from "@apollo/client";
import { GET_USER } from "shared/queries/queries";
import { User } from "shared/types/generated-types";

export const useGetUser = ({
  auth0Id,
  isLoggedIn,
}: {
  auth0Id?: string;
  isLoggedIn?: boolean;
}): { user: User | null; loading: boolean; error?: ApolloError } => {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { auth0Id },
    skip: !isLoggedIn || !auth0Id,
  });

  return { user: data?.user, loading, error };
};
