interface Props {
  width?: number;
  height?: number;
}

export const DisabledShowBtn = ({ width = 18, height = 18 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 33c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C9.716 3 3 9.716 3 18c0 8.284 6.716 15 15 15z"
        stroke="#C5C5C5"
        strokeWidth="2"
      />
      <path
        d="m12 15.75 6 6 6-6"
        stroke="#C5C5C5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
