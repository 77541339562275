interface Props {
  width?: number;
  height?: number;
  marginLeft?: number;
}

export const ShowIcon = ({
  width = 18,
  height = 18,
  marginLeft = 0,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: marginLeft, minWidth: width, minHeight: height }}
    >
      <path
        d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
        fill="#000"
      />
      <path
        d="m9 12.75 6 6 6-6"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
