import styled from "@emotion/styled";
import { Link, Stack, Typography } from "@mui/material";
import { ModalContentType } from "modules/termsOfService/types";
import { useState } from "react";
import TermsAndPrivacy from "../../modules/termsOfService/TermsAndPrivacy";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentType, setContentType] = useState<ModalContentType>("terms");

  const handleClose = () => setIsOpen(false);
  const handleClick = (type: ModalContentType) => {
    setIsOpen(true);
    setContentType(type);
  };

  const currYear = new Date().getFullYear();

  return (
    <>
      <TermsAndPrivacy
        isOpen={isOpen}
        handleClose={handleClose}
        contentType={contentType}
      />
      <Container>
        <FooterText color="primary.contrastText" marginLeft="2rem">
          Copyright © {currYear} CIC
        </FooterText>
        <FooterStack direction="row">
          <FooterLink>
            <FooterText
              onClick={() => handleClick("terms")}
              color="primary.contrastText"
            >
              Terms
            </FooterText>
          </FooterLink>
          <FooterLink>
            <FooterText
              onClick={() => handleClick("privacy")}
              color="primary.contrastText"
            >
              Privacy Policy
            </FooterText>
          </FooterLink>
          <FooterLink href="mailto:khoury-cic@northeastern.edu">
            <FooterText color="primary.contrastText">Contact Us</FooterText>
          </FooterLink>
        </FooterStack>
      </Container>
    </>
  );
};

const Container = styled(Stack)({
  width: "100%",
  height: "27px",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  backgroundColor: "#000000",
});

const FooterStack = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",
  margin: "0 8rem",
  height: "100%",
  gap: "1rem",
});

const FooterLink = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
});

const FooterText = styled(Typography)({
  fontSize: "10px",
});

export default Footer;
