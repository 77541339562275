import Content from "./content/Content";
import Header from "./Header";

interface Props {
  isLoading?: boolean;
}

const DashboardLayout = ({ isLoading }: Props) => {
  return (
    <>
      <Header />
      <Content isLoading={isLoading} />
    </>
  );
};

export default DashboardLayout;
