import styled from "@emotion/styled";
import { Box, IconButton, Modal } from "@mui/material";
import { CloseIcon } from "assets/icons/CloseIcon";
import React from "react";
import { colors } from "shared/styles/colors";

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  disableCloseButton?: boolean;
  noXPadding?: boolean;
}

const BaseModal = ({
  open,
  onClose,
  children,
  width,
  maxWidth,
  height,
  maxHeight,
  disableCloseButton,
  noXPadding,
}: BaseModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer
        noXPadding={noXPadding}
        width={width || "60%"}
        height={height}
        maxWidth={maxWidth || "1000px"}
        maxHeight={maxHeight || "400px"}
      >
        {!disableCloseButton && (
          <CloseIcon onClick={onClose} top="28px" right="26px" />
        )}
        {children}
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "noXPadding",
})(({ noXPadding }: { noXPadding?: boolean }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: colors.light,
  borderRadius: "10px",
  padding: `5px ${noXPadding ? "0px" : "10px"}`,
  boxShadow: `0 8px 8px 0 ${colors.boxShadow}`,
}));

export default BaseModal;
