import { Course, GraduationAndRetention, Seasons } from "./generated-types";

export enum Status {
  Uploaded = "UPLOADED",
  Pending = "PENDING",
  PastDue = "PAST_DUE",
  BeforePending = "INACTIVE",
  NotRequired = "NOT_REQUIRED",
  Historical = "HISTORICAL",
}
interface DataPeriodCourse {
  id: number;
  data: Course[];
  status: Status;
}

interface DataPeriodGr {
  id: number;
  data: GraduationAndRetention[];
  status: Status;
}
export interface DataPeriodCourses {
  CS1: DataPeriodCourse;
  CS2: DataPeriodCourse;
  CS3: DataPeriodCourse;
  GR: DataPeriodGr;
}
export interface DataPeriod {
  id: string;
  type: Seasons;
  courses: DataPeriodCourses;
  disabled?: boolean;
  status: Status;
  periodId?: number;
}
